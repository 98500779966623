import {Avatars} from '../../avatars';
import {AvatarSignProps} from '../../types/avatar';
import './AvatarStyles.scss'
import React from 'react';

function Orange({
                    signText,
                    displaySign,
                    grayScale,
                    outline,
                }: AvatarSignProps): React.ReactElement {
    const palettes = {
        standard: {
            body: '#FF8550',
            bodyBorder: 'black',
            sign: 'white',
            signBorder: 'black',
            hands: '#FFFFFD',
            arms: 'black',
        },
        grayscale: {
            body: '#B5B5B5',
            bodyBorder: '#828282',
            sign: '#B5B5B5',
            signBorder: '#828282',
            hands: '#B5B5B5',
            arms: '#828282',
        },
    };
    const palette = grayScale ? palettes.grayscale : palettes.standard;
    const outlineStrokeWidth = 20;
    const outlineStrokeColor = 'white';

    return (
        <svg
            data-testid={Avatars.ORANGE.name}
            className='avatar'
            width='204'
            height='298'
            viewBox='0 0 204 303'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            {outline && (
                <>
                    {/* Body Outline */}
                    <path
                        d='M102.205 297.185C138.894 297.185 168.636 267.443 168.636 230.754C168.636 194.066 138.894 164.324 102.205 164.324C65.5168 164.324 35.7749 194.066 35.7749 230.754C35.7749 267.443 65.5168 297.185 102.205 297.185Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Right Eye */}
                    <path
                        d='M83.2911 254.743C86.0937 254.743 88.3656 252.471 88.3656 249.668C88.3656 246.866 86.0937 244.594 83.2911 244.594C80.4885 244.594 78.2166 246.866 78.2166 249.668C78.2166 252.471 80.4885 254.743 83.2911 254.743Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Left Eye */}
                    <path
                        d='M121.119 251.052C123.922 251.052 126.194 248.78 126.194 245.978C126.194 243.175 123.922 240.903 121.119 240.903C118.317 240.903 116.045 243.175 116.045 245.978C116.045 248.78 118.317 251.052 121.119 251.052Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Mouth */}
                    <path
                        d='M97.6379 260.842C98.0847 262.238 98.831 263.519 99.8246 264.597C100.399 265.163 101.097 265.587 101.865 265.834C102.633 266.08 103.448 266.143 104.244 266.018C107.538 265.538 109.466 262.327 109.319 259.144C109.319 258.777 109.173 258.425 108.913 258.165C108.654 257.906 108.302 257.76 107.935 257.76C107.568 257.76 107.216 257.906 106.956 258.165C106.696 258.425 106.551 258.777 106.551 259.144C106.589 259.655 106.545 260.169 106.422 260.666C106.323 261.15 106.122 261.606 105.831 262.004L105.72 262.17C105.61 262.299 105.499 262.419 105.388 262.53C105.388 262.53 105.065 262.807 105.259 262.659C105.157 262.742 105.038 262.807 104.927 262.881L104.576 263.074C104.336 263.204 104.705 263.074 104.41 263.139C104.16 263.222 103.904 263.287 103.644 263.333C103.93 263.333 103.506 263.333 103.488 263.333C103.355 263.342 103.223 263.342 103.091 263.333C103.368 263.333 102.98 263.333 102.943 263.333L102.565 263.222C102.796 263.296 102.445 263.157 102.417 263.148L102.085 262.945C101.827 262.788 102.168 263.047 101.965 262.853L101.688 262.595C101.596 262.502 101.513 262.401 101.43 262.309C101.347 262.216 101.485 262.41 101.338 262.17C101.19 261.93 101.052 261.737 100.923 261.506C100.782 261.255 100.655 260.996 100.544 260.731L100.461 260.546C100.507 260.657 100.507 260.666 100.461 260.546L100.295 260.122C100.181 259.782 99.9421 259.499 99.6273 259.328C99.3125 259.157 98.9446 259.111 98.5975 259.199C98.2435 259.298 97.943 259.533 97.7615 259.853C97.58 260.173 97.5323 260.551 97.6287 260.906L97.6379 260.842Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Right Outer Leaf */}
                    <path
                        d='M97.5918 170.413C98.662 163.484 96.217 155.891 90.6258 151.443C87.3867 148.984 83.4364 147.644 79.3695 147.624C74.9405 147.57 70.5117 147.751 66.1019 148.168C63.7584 148.316 61.4149 148.5 59.0714 148.574C57.4296 148.567 55.8259 148.08 54.4581 147.172C54.1938 147.027 53.8896 146.971 53.5912 147.014C53.2928 147.057 53.0163 147.195 52.8032 147.408C52.59 147.621 52.4518 147.898 52.4092 148.196C52.3665 148.495 52.4218 148.799 52.5667 149.063C55.4707 155.034 60.3925 159.786 66.4617 162.478C69.5341 163.846 72.8288 164.646 76.1864 164.84C80.2645 165.08 84.4902 164.407 88.4945 165.505C91.7238 166.427 95.1744 168.9 95.1929 172.554C95.1929 172.921 95.3387 173.273 95.5982 173.532C95.8578 173.792 96.2098 173.938 96.5768 173.938C96.9439 173.938 97.2959 173.792 97.5555 173.532C97.815 173.273 97.9608 172.921 97.9608 172.554C97.9608 169.177 95.9125 166.4 93.1723 164.6C89.9892 162.506 86.1786 162.128 82.4604 162.109C78.5299 162.109 74.6825 162.266 70.8627 161.187C67.4133 160.186 64.2136 158.469 61.4727 156.148C58.7319 153.827 56.5114 150.954 54.9564 147.716L53.0649 149.561C54.4846 150.464 56.0784 151.058 57.7427 151.305C59.2816 151.39 60.8249 151.343 62.356 151.167C66.2587 150.945 70.1615 150.659 74.0736 150.475C78.2255 150.281 82.7095 150.069 86.5108 152.034C89.3665 153.564 91.6729 155.948 93.1077 158.852C94.8377 162.179 95.483 165.963 94.953 169.675C94.6854 171.419 97.3519 172.175 97.6194 170.413H97.5918Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Left Outer Leaf */}
                    <path
                        d='M98.9019 173.901C103.672 163.318 115.943 160.522 126.314 158.816L124.967 156.5C122.568 158.437 123.214 161.777 121.654 164.167C120.095 166.557 117.309 167.516 114.753 168.088C108.645 169.444 102.03 168.088 96.8352 172.397C95.4604 173.532 97.4257 175.478 98.7912 174.353C101.273 172.314 104.613 171.852 107.713 171.585C110.813 171.317 113.793 171.28 116.727 170.432C119.471 169.766 121.921 168.22 123.703 166.031C124.488 164.925 125.035 163.668 125.308 162.34C125.622 160.938 125.732 159.388 126.923 158.428C128.113 157.468 126.766 155.918 125.576 156.121C114.504 157.967 101.587 161.242 96.5122 172.471C95.7834 174.085 98.1638 175.497 98.9019 173.873V173.901Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Inner Stem */}
                    <path
                        d='M97.5367 173.153C98.9668 169.297 102.233 164.517 103.663 160.661C103.875 159.083 101.237 158.493 100.24 159.738C99.2436 160.984 97.7212 163.733 98.3302 165.209C98.9391 166.686 97.223 167.811 97.1307 169.398'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Outer Stem */}
                    <path
                        d='M98.8746 173.55C99.8045 171.306 100.89 169.129 102.122 167.036C102.713 165.938 103.313 164.831 103.857 163.696C104.337 162.7 105.13 161.436 105.038 160.301C104.853 158.003 102.002 157.182 100.176 158.086C99.3394 158.605 98.6676 159.351 98.238 160.236C97.6533 161.161 97.2204 162.173 96.9555 163.235C96.802 163.882 96.7769 164.553 96.8817 165.209C96.8817 165.421 97.0293 165.624 97.0754 165.846C97.1216 166.067 97.0754 165.929 97.0293 166.104C96.9094 166.455 96.5587 167.027 96.3465 167.414C96.0202 168.04 95.8166 168.723 95.7468 169.426C95.7492 169.792 95.8958 170.143 96.1548 170.402C96.4139 170.661 96.7645 170.807 97.1308 170.81C97.4956 170.803 97.8435 170.654 98.1016 170.396C98.3596 170.138 98.5077 169.79 98.5148 169.426C98.7384 168.772 99.0487 168.151 99.4374 167.58C99.6573 167.157 99.7894 166.694 99.8259 166.218C99.8623 165.742 99.8024 165.264 99.6496 164.812C99.5666 164.6 99.5943 164.683 99.6496 164.812C99.6406 164.714 99.6406 164.615 99.6496 164.517C99.6496 164.462 99.6496 164.148 99.6496 164.25C99.7199 163.838 99.8375 163.435 100 163.05C100.328 162.266 100.756 161.528 101.273 160.854C101.375 160.716 101.273 160.854 101.273 160.854C101.273 160.854 101.403 160.725 101.458 160.661C101.384 160.744 101.504 160.661 101.458 160.661C101.412 160.661 101.929 160.587 101.689 160.605H101.901C102.058 160.605 101.744 160.605 102.002 160.605L102.251 160.679C102.556 160.771 102.251 160.679 102.408 160.79C102.408 160.79 102.353 160.614 102.344 160.614V160.55C102.344 160.688 102.344 160.716 102.344 160.651C102.344 160.587 102.251 160.827 102.224 160.891C101.415 162.791 100.49 164.64 99.4558 166.427C98.2603 168.504 97.2121 170.662 96.3189 172.886C96.2209 173.233 96.2625 173.605 96.4349 173.922C96.6073 174.239 96.8967 174.476 97.2415 174.583C97.59 174.674 97.9601 174.63 98.277 174.459C98.5939 174.287 98.8338 174.002 98.9484 173.661L98.8746 173.55Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Right Inner Leaf */}
                    <path
                        d='M99.5756 171.705C96.1748 166.239 91.5072 161.673 85.968 158.394C80.4289 155.114 74.1811 153.218 67.7536 152.864C65.9729 152.781 65.9729 155.549 67.7536 155.632C73.7007 155.974 79.4787 157.739 84.6008 160.781C89.7229 163.822 94.0392 168.049 97.186 173.107C98.1086 174.62 100.507 173.236 99.5756 171.705Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Left Inner Leaf */}
                    <path
                        d='M101.125 171.022C107.62 170.188 113.843 167.898 119.329 164.323C120.815 163.355 119.431 160.956 117.936 161.934C112.878 165.28 107.133 167.446 101.125 168.272C100.762 168.284 100.417 168.433 100.16 168.691C99.9025 168.948 99.753 169.293 99.7415 169.656C99.7415 170.33 100.378 171.142 101.125 171.04V171.022Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                </>
            )}
            {/* Body */}
            <path
                d='M102.205 297.185C138.894 297.185 168.636 267.443 168.636 230.754C168.636 194.066 138.894 164.324 102.205 164.324C65.5168 164.324 35.7749 194.066 35.7749 230.754C35.7749 267.443 65.5168 297.185 102.205 297.185Z'
                fill={palette.body}
            />
            {/* Body Outline */}
            <path
                d='M102.205 297.185C138.894 297.185 168.636 267.443 168.636 230.754C168.636 194.066 138.894 164.324 102.205 164.324C65.5168 164.324 35.7749 194.066 35.7749 230.754C35.7749 267.443 65.5168 297.185 102.205 297.185Z'
                stroke={outlineStrokeColor}
                strokeWidth='3'
                strokeLinejoin='round'
            />
            {/* Right Eye */}
            <path
                d='M83.2911 254.743C86.0937 254.743 88.3656 252.471 88.3656 249.668C88.3656 246.866 86.0937 244.594 83.2911 244.594C80.4885 244.594 78.2166 246.866 78.2166 249.668C78.2166 252.471 80.4885 254.743 83.2911 254.743Z'
                fill={palette.bodyBorder}
            />
            {/* Left Eye */}
            <path
                d='M121.119 251.052C123.922 251.052 126.194 248.78 126.194 245.978C126.194 243.175 123.922 240.903 121.119 240.903C118.317 240.903 116.045 243.175 116.045 245.978C116.045 248.78 118.317 251.052 121.119 251.052Z'
                fill={palette.bodyBorder}
            />
            {/* Mouth */}
            <path
                d='M97.6379 260.842C98.0847 262.238 98.831 263.519 99.8246 264.597C100.399 265.163 101.097 265.587 101.865 265.834C102.633 266.08 103.448 266.143 104.244 266.018C107.538 265.538 109.466 262.327 109.319 259.144C109.319 258.777 109.173 258.425 108.913 258.165C108.654 257.906 108.302 257.76 107.935 257.76C107.568 257.76 107.216 257.906 106.956 258.165C106.696 258.425 106.551 258.777 106.551 259.144C106.589 259.655 106.545 260.169 106.422 260.666C106.323 261.15 106.122 261.606 105.831 262.004L105.72 262.17C105.61 262.299 105.499 262.419 105.388 262.53C105.388 262.53 105.065 262.807 105.259 262.659C105.157 262.742 105.038 262.807 104.927 262.881L104.576 263.074C104.336 263.204 104.705 263.074 104.41 263.139C104.16 263.222 103.904 263.287 103.644 263.333C103.93 263.333 103.506 263.333 103.488 263.333C103.355 263.342 103.223 263.342 103.091 263.333C103.368 263.333 102.98 263.333 102.943 263.333L102.565 263.222C102.796 263.296 102.445 263.157 102.417 263.148L102.085 262.945C101.827 262.788 102.168 263.047 101.965 262.853L101.688 262.595C101.596 262.502 101.513 262.401 101.43 262.309C101.347 262.216 101.485 262.41 101.338 262.17C101.19 261.93 101.052 261.737 100.923 261.506C100.782 261.255 100.655 260.996 100.544 260.731L100.461 260.546C100.507 260.657 100.507 260.666 100.461 260.546L100.295 260.122C100.181 259.782 99.9421 259.499 99.6273 259.328C99.3125 259.157 98.9446 259.111 98.5975 259.199C98.2435 259.298 97.943 259.533 97.7615 259.853C97.58 260.173 97.5323 260.551 97.6287 260.906L97.6379 260.842Z'
                fill={palette.bodyBorder}
            />
            {/* Right Outer Leaf */}
            <path
                d='M97.5918 170.413C98.662 163.484 96.217 155.891 90.6258 151.443C87.3867 148.984 83.4364 147.644 79.3695 147.624C74.9405 147.57 70.5117 147.751 66.1019 148.168C63.7584 148.316 61.4149 148.5 59.0714 148.574C57.4296 148.567 55.8259 148.08 54.4581 147.172C54.1938 147.027 53.8896 146.971 53.5912 147.014C53.2928 147.057 53.0163 147.195 52.8032 147.408C52.59 147.621 52.4518 147.898 52.4092 148.196C52.3665 148.495 52.4218 148.799 52.5667 149.063C55.4707 155.034 60.3925 159.786 66.4617 162.478C69.5341 163.846 72.8288 164.646 76.1864 164.84C80.2645 165.08 84.4902 164.407 88.4945 165.505C91.7238 166.427 95.1744 168.9 95.1929 172.554C95.1929 172.921 95.3387 173.273 95.5982 173.532C95.8578 173.792 96.2098 173.938 96.5768 173.938C96.9439 173.938 97.2959 173.792 97.5555 173.532C97.815 173.273 97.9608 172.921 97.9608 172.554C97.9608 169.177 95.9125 166.4 93.1723 164.6C89.9892 162.506 86.1786 162.128 82.4604 162.109C78.5299 162.109 74.6825 162.266 70.8627 161.187C67.4133 160.186 64.2136 158.469 61.4727 156.148C58.7319 153.827 56.5114 150.954 54.9564 147.716L53.0649 149.561C54.4846 150.464 56.0784 151.058 57.7427 151.305C59.2816 151.39 60.8249 151.343 62.356 151.167C66.2587 150.945 70.1615 150.659 74.0736 150.475C78.2255 150.281 82.7095 150.069 86.5108 152.034C89.3665 153.564 91.6729 155.948 93.1077 158.852C94.8377 162.179 95.483 165.963 94.953 169.675C94.6854 171.419 97.3519 172.175 97.6194 170.413H97.5918Z'
                fill={palette.bodyBorder}
            />
            {/* Left Outer Leaf */}
            <path
                d='M98.9019 173.901C103.672 163.318 115.943 160.522 126.314 158.816L124.967 156.5C122.568 158.437 123.214 161.777 121.654 164.167C120.095 166.557 117.309 167.516 114.753 168.088C108.645 169.444 102.03 168.088 96.8352 172.397C95.4604 173.532 97.4257 175.478 98.7912 174.353C101.273 172.314 104.613 171.852 107.713 171.585C110.813 171.317 113.793 171.28 116.727 170.432C119.471 169.766 121.921 168.22 123.703 166.031C124.488 164.925 125.035 163.668 125.308 162.34C125.622 160.938 125.732 159.388 126.923 158.428C128.113 157.468 126.766 155.918 125.576 156.121C114.504 157.967 101.587 161.242 96.5122 172.471C95.7834 174.085 98.1638 175.497 98.9019 173.873V173.901Z'
                fill={palette.bodyBorder}
            />
            {/* Inner Stem */}
            <path
                d='M97.5367 173.153C98.9668 169.297 102.233 164.517 103.663 160.661C103.875 159.083 101.237 158.493 100.24 159.738C99.2436 160.984 97.7212 163.733 98.3302 165.209C98.9391 166.686 97.223 167.811 97.1307 169.398'
                fill={palette.bodyBorder}
            />
            {/* Outer Stem */}
            <path
                d='M98.8746 173.55C99.8045 171.306 100.89 169.129 102.122 167.036C102.713 165.938 103.313 164.831 103.857 163.696C104.337 162.7 105.13 161.436 105.038 160.301C104.853 158.003 102.002 157.182 100.176 158.086C99.3394 158.605 98.6676 159.351 98.238 160.236C97.6533 161.161 97.2204 162.173 96.9555 163.235C96.802 163.882 96.7769 164.553 96.8817 165.209C96.8817 165.421 97.0293 165.624 97.0754 165.846C97.1216 166.067 97.0754 165.929 97.0293 166.104C96.9094 166.455 96.5587 167.027 96.3465 167.414C96.0202 168.04 95.8166 168.723 95.7468 169.426C95.7492 169.792 95.8958 170.143 96.1548 170.402C96.4139 170.661 96.7645 170.807 97.1308 170.81C97.4956 170.803 97.8435 170.654 98.1016 170.396C98.3596 170.138 98.5077 169.79 98.5148 169.426C98.7384 168.772 99.0487 168.151 99.4374 167.58C99.6573 167.157 99.7894 166.694 99.8259 166.218C99.8623 165.742 99.8024 165.264 99.6496 164.812C99.5666 164.6 99.5943 164.683 99.6496 164.812C99.6406 164.714 99.6406 164.615 99.6496 164.517C99.6496 164.462 99.6496 164.148 99.6496 164.25C99.7199 163.838 99.8375 163.435 100 163.05C100.328 162.266 100.756 161.528 101.273 160.854C101.375 160.716 101.273 160.854 101.273 160.854C101.273 160.854 101.403 160.725 101.458 160.661C101.384 160.744 101.504 160.661 101.458 160.661C101.412 160.661 101.929 160.587 101.689 160.605H101.901C102.058 160.605 101.744 160.605 102.002 160.605L102.251 160.679C102.556 160.771 102.251 160.679 102.408 160.79C102.408 160.79 102.353 160.614 102.344 160.614V160.55C102.344 160.688 102.344 160.716 102.344 160.651C102.344 160.587 102.251 160.827 102.224 160.891C101.415 162.791 100.49 164.64 99.4558 166.427C98.2603 168.504 97.2121 170.662 96.3189 172.886C96.2209 173.233 96.2625 173.605 96.4349 173.922C96.6073 174.239 96.8967 174.476 97.2415 174.583C97.59 174.674 97.9601 174.63 98.277 174.459C98.5939 174.287 98.8338 174.002 98.9484 173.661L98.8746 173.55Z'
                fill={palette.bodyBorder}
            />
            {/* Right Inner Leaf */}
            <path
                d='M99.5756 171.705C96.1748 166.239 91.5072 161.673 85.968 158.394C80.4289 155.114 74.1811 153.218 67.7536 152.864C65.9729 152.781 65.9729 155.549 67.7536 155.632C73.7007 155.974 79.4787 157.739 84.6008 160.781C89.7229 163.822 94.0392 168.049 97.186 173.107C98.1086 174.62 100.507 173.236 99.5756 171.705Z'
                fill={palette.bodyBorder}
            />
            {/* Left Inner Leaf */}
            <path
                d='M101.125 171.022C107.62 170.188 113.843 167.898 119.329 164.323C120.815 163.355 119.431 160.956 117.936 161.934C112.878 165.28 107.133 167.446 101.125 168.272C100.762 168.284 100.417 168.433 100.16 168.691C99.9025 168.948 99.753 169.293 99.7415 169.656C99.7415 170.33 100.378 171.142 101.125 171.04V171.022Z'
                fill={palette.bodyBorder}
            />
            {displaySign && (
                <>
                    {/* Sign Image */}
                    <rect
                        x='6.25722'
                        y='14.3346'
                        width='180'
                        height='115'
                        transform='rotate(-3.77907 6.25722 14.3346)'
                        fill={palette.sign}
                        stroke={palette.signBorder}
                        strokeWidth='3'
                    />
                    {/* Sign Content */}
                    <foreignObject
                        x='6.25722'
                        y='14.3346'
                        width='180'
                        height='115'
                        transform='rotate(-3.77907 6.25722 14.3346)'
                    >
                        <div>
                            <p>{signText}</p>
                        </div>
                    </foreignObject>
                    {/* Right Arm */}
                        <path
                            d='M59.119 193.416C56.0918 190.954 54.2413 187.375 52.5477 183.849C44.2162 166.83 36.4773 149.537 29.331 131.972'
                            fill={palette.hands}
                        />
                        <path
                            d='M60.155 192.332C56.7938 189.495 54.9817 185.427 53.0889 181.545C51.1962 177.663 49.2939 173.675 47.4416 169.707C43.6831 161.683 40.0531 153.607 36.5515 145.478C34.5919 140.841 32.6467 136.217 30.716 131.608C30.0034 129.835 27.1081 130.651 27.8352 132.438C34.6661 149.204 42.034 165.741 49.9389 182.049C52.1067 186.512 54.1544 191.288 58.0346 194.552C59.5059 195.791 61.5925 193.635 60.1066 192.382L60.155 192.332Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M29.5789 132.292C28.2144 133.547 26.117 133.383 24.2725 133.142C22.7792 133.096 21.3396 132.574 20.1643 131.652C18.8915 130.436 18.7829 128.48 18.7354 126.72C18.6281 125.337 18.7176 123.947 19.0013 122.59C19.3588 121.29 20.0582 120.11 21.0269 119.172C21.9956 118.234 23.198 117.573 24.5089 117.258C25.7512 116.989 27.0416 117.044 28.2564 117.418C29.4713 117.791 30.5699 118.47 31.4469 119.39C32.3007 120.324 32.8954 121.464 33.1719 122.699C33.4484 123.933 33.3971 125.218 33.0231 126.427C32.7215 127.273 32.3121 128.076 31.8049 128.817C31.0659 129.936 30.2154 130.977 29.2663 131.924'
                            fill={palette.hands}
                        />
                        <path
                            d='M28.4943 131.255C27.655 131.975 26.2307 131.859 25.2384 131.769C23.9972 131.621 22.447 131.564 21.4533 130.795C20.4595 130.025 20.3455 128.451 20.2969 127.257C20.1584 125.828 20.2302 124.387 20.5099 122.98C20.8441 121.751 21.565 120.662 22.566 119.875C23.567 119.088 24.7948 118.644 26.0678 118.608C27.292 118.583 28.4921 118.95 29.4922 119.657C30.4924 120.363 31.2399 121.371 31.6253 122.533C32.6414 125.813 30.409 128.701 28.2176 130.908C26.8846 132.304 29.054 134.376 30.3871 132.98C33.2076 130.143 35.5777 126.474 34.6612 122.294C34.2282 120.488 33.2312 118.867 31.8149 117.666C30.3985 116.465 28.6365 115.746 26.7841 115.614C24.9217 115.494 23.0687 115.963 21.4871 116.953C19.9055 117.944 18.6754 119.407 17.9708 121.135C17.334 123.026 17.1028 125.029 17.2924 127.014C17.1972 128.91 17.7409 130.783 18.8362 132.333C20.2765 134.068 22.5691 134.419 24.6823 134.653C26.7954 134.888 28.95 134.774 30.6842 133.292C30.9565 133.003 31.1041 132.618 31.095 132.221C31.0859 131.824 30.9208 131.447 30.6355 131.171C30.3442 130.903 29.9607 130.759 29.5653 130.768C29.1699 130.777 28.7934 130.939 28.5147 131.22L28.4943 131.255Z'
                            fill={palette.arms}
                        />
                    {/* Left Arm */}
                        <path
                            d='M144.748 180.442C145.085 176.554 147.005 173.012 148.97 169.63C158.368 153.175 168.324 137.057 178.839 121.276'
                            fill={palette.hands}
                        />
                        <path
                            d='M146.228 180.69C146.686 176.315 149.033 172.53 151.18 168.784C153.328 165.037 155.558 161.222 157.8 157.46C162.325 149.843 166.966 142.302 171.723 134.838C174.458 130.611 177.19 126.404 179.918 122.217C180.984 120.631 178.682 118.695 177.613 120.3C167.582 135.372 158.042 150.759 148.994 166.461C146.52 170.762 143.719 175.14 143.2 180.183C143.002 182.096 145.957 182.611 146.159 180.678L146.228 180.69Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M178.713 121.661C176.909 121.237 175.866 119.41 175.028 117.749C174.227 116.488 173.85 115.004 173.952 113.513C174.243 111.777 175.8 110.588 177.23 109.56C178.313 108.694 179.513 107.987 180.795 107.459C182.071 107.025 183.441 106.94 184.761 107.214C186.081 107.489 187.303 108.112 188.301 109.019C189.221 109.896 189.9 110.994 190.274 112.209C190.648 113.424 190.703 114.714 190.435 115.957C190.143 117.187 189.533 118.32 188.668 119.243C187.802 120.165 186.71 120.845 185.5 121.214C184.631 121.44 183.736 121.553 182.838 121.55C181.498 121.567 180.159 121.448 178.842 121.195'
                            fill={palette.hands}
                        />
                        <path
                            d='M178.961 120.181C177.894 119.891 177.19 118.648 176.707 117.776C176.132 116.666 175.308 115.352 175.386 114.098C175.464 112.843 176.702 111.864 177.663 111.153C178.767 110.236 179.999 109.486 181.32 108.926C182.525 108.512 183.83 108.497 185.044 108.882C186.258 109.268 187.315 110.034 188.059 111.067C188.768 112.066 189.139 113.265 189.116 114.489C189.094 115.713 188.68 116.898 187.935 117.87C185.793 120.553 182.15 120.329 179.093 119.757C177.189 119.438 176.694 122.397 178.598 122.716C182.53 123.455 186.896 123.354 189.839 120.247C191.09 118.874 191.87 117.138 192.068 115.292C192.266 113.446 191.87 111.584 190.939 109.978C189.991 108.37 188.563 107.1 186.854 106.348C185.146 105.597 183.245 105.401 181.419 105.789C179.498 106.325 177.711 107.259 176.175 108.532C174.553 109.518 173.31 111.02 172.643 112.797C172.017 114.964 173.015 117.057 174.008 118.937C175.002 120.816 176.306 122.535 178.507 123.137C178.899 123.2 179.3 123.106 179.623 122.875C179.946 122.644 180.166 122.296 180.234 121.905C180.292 121.513 180.196 121.115 179.966 120.793C179.736 120.471 179.391 120.251 179.002 120.178L178.961 120.181Z'
                            fill={palette.arms}
                        />
                </>
            )}
        </svg>
    );
}

export default Orange;
