import {Avatars} from '../../avatars';
import {AvatarSignProps} from '../../types/avatar';
import './AvatarStyles.scss'
import React from 'react';

function Raspberry({
                       signText,
                       displaySign,
                       grayScale,
                       outline,
                   }: AvatarSignProps): React.ReactElement {
    const palettes = {
        standard: {
            body: '#E932E9',
            bodyBorder: 'black',
            sign: 'white',
            signBorder: 'black',
            hands: '#FFFFFD',
            arms: 'black',
        },
        grayscale: {
            body: '#B5B5B5',
            bodyBorder: '#828282',
            sign: '#B5B5B5',
            signBorder: '#828282',
            hands: '#B5B5B5',
            arms: '#828282',
        },
    };

    const palette = grayScale ? palettes.grayscale : palettes.standard;
    const outlineStrokeWidth = 20;
    const outlineStrokeColor = 'white';

    return (
        <svg
            data-testid={Avatars.RASPBERRY.name}
            className='avatar'
            width='238'
            height='310'
            viewBox='-10 0 238 315'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            {outline && (
                <>
                    <path
                        d='M62.6713 218.1C71.5886 218.1 78.8176 210.871 78.8176 201.954C78.8176 193.037 71.5886 185.808 62.6713 185.808C53.754 185.808 46.525 193.037 46.525 201.954C46.525 210.871 53.754 218.1 62.6713 218.1Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M85.7375 207.951C94.6548 207.951 101.884 200.722 101.884 191.805C101.884 182.887 94.6548 175.658 85.7375 175.658C76.8201 175.658 69.5912 182.887 69.5912 191.805C69.5912 200.722 76.8201 207.951 85.7375 207.951Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M67.2846 231.017C76.2019 231.017 83.4309 223.788 83.4309 214.871C83.4309 205.954 76.2019 198.725 67.2846 198.725C58.3672 198.725 51.1383 205.954 51.1383 214.871C51.1383 223.788 58.3672 231.017 67.2846 231.017Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M93.1186 231.017C102.036 231.017 109.265 223.788 109.265 214.871C109.265 205.954 102.036 198.725 93.1186 198.725C84.2012 198.725 76.9723 205.954 76.9723 214.871C76.9723 223.788 84.2012 231.017 93.1186 231.017Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M112.494 203.338C121.412 203.338 128.64 196.109 128.64 187.191C128.64 178.274 121.412 171.045 112.494 171.045C103.577 171.045 96.3479 178.274 96.3479 187.191C96.3479 196.109 103.577 203.338 112.494 203.338Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M135.56 204.26C144.478 204.26 151.706 197.031 151.706 188.114C151.706 179.197 144.478 171.968 135.56 171.968C126.643 171.968 119.414 179.197 119.414 188.114C119.414 197.031 126.643 204.26 135.56 204.26Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M149.4 227.326C158.317 227.326 165.546 220.098 165.546 211.18C165.546 202.263 158.317 195.034 149.4 195.034C140.483 195.034 133.254 202.263 133.254 211.18C133.254 220.098 140.483 227.326 149.4 227.326Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M132.792 227.326C141.71 227.326 148.939 220.098 148.939 211.18C148.939 202.263 141.71 195.034 132.792 195.034C123.875 195.034 116.646 202.263 116.646 211.18C116.646 220.098 123.875 227.326 132.792 227.326Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M105.113 225.481C114.03 225.481 121.259 218.252 121.259 209.335C121.259 200.418 114.03 193.189 105.113 193.189C96.1956 193.189 88.9667 200.418 88.9667 209.335C88.9667 218.252 96.1956 225.481 105.113 225.481Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M111.572 242.089C120.489 242.089 127.718 234.86 127.718 225.943C127.718 217.025 120.489 209.796 111.572 209.796C102.654 209.796 95.4253 217.025 95.4253 225.943C95.4253 234.86 102.654 242.089 111.572 242.089Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M78.3564 255.006C87.2737 255.006 94.5026 247.777 94.5026 238.86C94.5026 229.942 87.2737 222.713 78.3564 222.713C69.439 222.713 62.2101 229.942 62.2101 238.86C62.2101 247.777 69.439 255.006 78.3564 255.006Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M109.726 255.006C118.644 255.006 125.873 247.777 125.873 238.86C125.873 229.942 118.644 222.713 109.726 222.713C100.809 222.713 93.58 229.942 93.58 238.86C93.58 247.777 100.809 255.006 109.726 255.006Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M132.792 255.006C141.71 255.006 148.939 247.777 148.939 238.86C148.939 229.942 141.71 222.713 132.792 222.713C123.875 222.713 116.646 229.942 116.646 238.86C116.646 247.777 123.875 255.006 132.792 255.006Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M149.4 255.006C158.317 255.006 165.546 247.777 165.546 238.86C165.546 229.942 158.317 222.713 149.4 222.713C140.483 222.713 133.254 229.942 133.254 238.86C133.254 247.777 140.483 255.006 149.4 255.006Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M89.428 249.47C98.3454 249.47 105.574 242.241 105.574 233.324C105.574 224.406 98.3454 217.177 89.428 217.177C80.5107 217.177 73.2817 224.406 73.2817 233.324C73.2817 242.241 80.5107 249.47 89.428 249.47Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M128.179 251.315C137.096 251.315 144.325 244.086 144.325 235.169C144.325 226.252 137.096 219.023 128.179 219.023C119.262 219.023 112.033 226.252 112.033 235.169C112.033 244.086 119.262 251.315 128.179 251.315Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M74.6657 272.536C83.583 272.536 90.812 265.307 90.812 256.39C90.812 247.473 83.583 240.244 74.6657 240.244C65.7483 240.244 58.5194 247.473 58.5194 256.39C58.5194 265.307 65.7483 272.536 74.6657 272.536Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M99.5772 277.149C108.495 277.149 115.723 269.92 115.723 261.003C115.723 252.086 108.495 244.857 99.5772 244.857C90.6598 244.857 83.4309 252.086 83.4309 261.003C83.4309 269.92 90.6598 277.149 99.5772 277.149Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M117.107 277.149C126.025 277.149 133.254 269.92 133.254 261.003C133.254 252.086 126.025 244.857 117.107 244.857C108.19 244.857 100.961 252.086 100.961 261.003C100.961 269.92 108.19 277.149 117.107 277.149Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M133.715 277.149C142.632 277.149 149.861 269.92 149.861 261.003C149.861 252.086 142.632 244.857 133.715 244.857C124.798 244.857 117.569 252.086 117.569 261.003C117.569 269.92 124.798 277.149 133.715 277.149Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M149.4 277.149C158.317 277.149 165.546 269.92 165.546 261.003C165.546 252.086 158.317 244.857 149.4 244.857C140.483 244.857 133.254 252.086 133.254 261.003C133.254 269.92 140.483 277.149 149.4 277.149Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M166.007 231.017C174.925 231.017 182.154 223.788 182.154 214.871C182.154 205.954 174.925 198.725 166.007 198.725C157.09 198.725 149.861 205.954 149.861 214.871C149.861 223.788 157.09 231.017 166.007 231.017Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M163.24 260.542C172.157 260.542 179.386 253.313 179.386 244.396C179.386 235.478 172.157 228.249 163.24 228.249C154.322 228.249 147.093 235.478 147.093 244.396C147.093 253.313 154.322 260.542 163.24 260.542Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M163.24 282.685C172.157 282.685 179.386 275.456 179.386 266.539C179.386 257.622 172.157 250.393 163.24 250.393C154.322 250.393 147.093 257.622 147.093 266.539C147.093 275.456 154.322 282.685 163.24 282.685Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M158.626 210.719C167.544 210.719 174.773 203.49 174.773 194.573C174.773 185.655 167.544 178.427 158.626 178.427C149.709 178.427 142.48 185.655 142.48 194.573C142.48 203.49 149.709 210.719 158.626 210.719Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M58.9808 242.089C67.8981 242.089 75.127 234.86 75.127 225.943C75.127 217.025 67.8981 209.796 58.9808 209.796C50.0634 209.796 42.8345 217.025 42.8345 225.943C42.8345 234.86 50.0634 242.089 58.9808 242.089Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M67.2846 267C76.2019 267 83.4309 259.771 83.4309 250.854C83.4309 241.937 76.2019 234.708 67.2846 234.708C58.3672 234.708 51.1383 241.937 51.1383 250.854C51.1383 259.771 58.3672 267 67.2846 267Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M80.2016 289.144C89.1189 289.144 96.3479 281.915 96.3479 272.997C96.3479 264.08 89.1189 256.851 80.2016 256.851C71.2842 256.851 64.0553 264.08 64.0553 272.997C64.0553 281.915 71.2842 289.144 80.2016 289.144Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M101.422 306.674C110.34 306.674 117.569 299.445 117.569 290.528C117.569 281.61 110.34 274.381 101.422 274.381C92.5051 274.381 85.2761 281.61 85.2761 290.528C85.2761 299.445 92.5051 306.674 101.422 306.674Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M125.411 309.442C134.329 309.442 141.557 302.213 141.557 293.296C141.557 284.378 134.329 277.149 125.411 277.149C116.494 277.149 109.265 284.378 109.265 293.296C109.265 302.213 116.494 309.442 125.411 309.442Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M148.477 302.983C157.395 302.983 164.624 295.755 164.624 286.837C164.624 277.92 157.395 270.691 148.477 270.691C139.56 270.691 132.331 277.92 132.331 286.837C132.331 295.755 139.56 302.983 148.477 302.983Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M127.257 295.602C136.174 295.602 143.403 288.373 143.403 279.456C143.403 270.539 136.174 263.31 127.257 263.31C118.339 263.31 111.11 270.539 111.11 279.456C111.11 288.373 118.339 295.602 127.257 295.602Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M115.262 292.834C124.179 292.834 131.408 285.605 131.408 276.688C131.408 267.771 124.179 260.542 115.262 260.542C106.345 260.542 99.1158 267.771 99.1158 276.688C99.1158 285.605 106.345 292.834 115.262 292.834Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M102.345 254.083C105.148 254.083 107.42 251.811 107.42 249.009C107.42 246.206 105.148 243.934 102.345 243.934C99.5425 243.934 97.2705 246.206 97.2705 249.009C97.2705 251.811 99.5425 254.083 102.345 254.083Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M140.173 250.393C142.976 250.393 145.248 248.121 145.248 245.318C145.248 242.516 142.976 240.244 140.173 240.244C137.371 240.244 135.099 242.516 135.099 245.318C135.099 248.121 137.371 250.393 140.173 250.393Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M116.692 260.182C117.139 261.578 117.885 262.86 118.879 263.937C119.452 264.503 120.151 264.927 120.919 265.174C121.687 265.421 122.502 265.484 123.298 265.358C126.592 264.878 128.52 261.667 128.373 258.484C128.373 258.117 128.227 257.765 127.967 257.506C127.708 257.246 127.356 257.1 126.989 257.1C126.622 257.1 126.27 257.246 126.01 257.506C125.75 257.765 125.605 258.117 125.605 258.484C125.643 258.995 125.599 259.509 125.475 260.007C125.377 260.49 125.176 260.946 124.885 261.344L124.774 261.511C124.664 261.64 124.553 261.76 124.442 261.87C124.442 261.87 124.119 262.147 124.313 262C124.211 262.083 124.092 262.147 123.981 262.221L123.63 262.415C123.39 262.544 123.759 262.415 123.464 262.479C123.214 262.562 122.958 262.627 122.698 262.673C122.984 262.673 122.56 262.673 122.541 262.673C122.409 262.682 122.277 262.682 122.145 262.673C122.422 262.673 122.034 262.673 121.997 262.673L121.619 262.562C121.849 262.636 121.499 262.498 121.471 262.489L121.139 262.286C120.881 262.129 121.222 262.387 121.019 262.193L120.742 261.935C120.65 261.843 120.567 261.741 120.484 261.649C120.401 261.557 120.539 261.75 120.392 261.511C120.244 261.271 120.106 261.077 119.976 260.846C119.836 260.595 119.709 260.337 119.598 260.071L119.515 259.887C119.515 259.997 119.515 260.007 119.515 259.887L119.349 259.462C119.235 259.123 118.996 258.839 118.681 258.668C118.366 258.497 117.999 258.451 117.651 258.54C117.297 258.639 116.997 258.874 116.815 259.194C116.634 259.513 116.586 259.892 116.683 260.247L116.692 260.182Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M108.767 170.971C97.5656 161.394 82.0098 161.284 68.2348 163.729C60.1616 165.159 52.2546 167.364 44.1814 168.757C36.1289 170.205 27.9448 170.79 19.7682 170.501C13.5588 170.215 6.07619 168.794 2.87461 162.797C2.15495 161.441 -0.234688 162.382 0.346578 163.858C2.88102 170.004 7.56826 175.016 13.5311 177.956C20.1073 181.201 27.4449 182.589 34.752 181.969C52.0239 180.862 67.6258 171.949 84.8147 170.51C92.7033 169.855 101.422 170.851 107.493 176.498C108.785 177.716 110.75 175.76 109.449 174.542C97.8885 163.719 79.7401 167.604 66.1865 171.59C51.1105 176.037 34.5305 182.837 18.8271 177.245C12.018 174.828 5.72557 170.003 3.03145 163.129L0.494177 164.19C3.37283 169.587 9.32389 171.968 15.0904 172.817C22.4716 173.905 30.3049 173.324 37.7229 172.466C46.0267 171.543 54.0352 169.541 62.1545 167.77C69.7848 166.109 77.5535 164.725 85.3959 165.177C93.1 165.62 100.915 167.844 106.866 172.937C108.213 174.081 110.178 172.134 108.822 170.971H108.767Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M110.067 173.333C112.734 160.795 128.908 158.986 139.297 158.054C142.757 157.741 146.235 157.584 149.713 157.565C152.897 157.565 156.098 157.805 159.272 157.565C162.426 157.39 165.494 156.476 168.231 154.899C170.825 153.379 173.263 151.608 175.511 149.612C180.124 145.571 183.888 139.14 181.286 132.931C180.622 131.344 178.066 131.916 178.62 133.669C180.299 139.029 178.74 144.667 175.575 149.151C172.115 154.05 166.672 157.113 161.062 158.912C148.348 163 134.601 161.099 121.84 164.956C115.566 166.856 109.542 170.104 105.408 175.299C104.31 176.673 106.257 178.648 107.364 177.255C115.945 166.487 130.707 165.657 143.347 164.642C155.6 163.655 169.643 161.69 177.55 151.116C181.397 145.977 183.242 139.177 181.24 132.931L178.574 133.669C180.936 139.306 177.098 144.658 172.927 148.154C170.717 150.068 168.297 151.724 165.712 153.09C162.828 154.426 159.66 155.038 156.486 154.871C149.835 154.7 143.181 154.922 136.557 155.536C130.43 156.218 124.092 157.095 118.464 159.752C113.204 162.225 108.61 166.736 107.392 172.577C107.023 174.311 109.689 175.049 110.058 173.306L110.067 173.333Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M109.053 174.948C112.577 166.976 102.243 162.031 96.7352 159.06C94.9389 158.081 93.0909 157.201 91.1994 156.421C89.3541 155.665 87.3889 155.13 85.5344 154.327C82.4591 152.975 79.9893 150.538 78.5961 147.481C77.0973 144.051 76.2134 140.385 75.985 136.649C75.985 136.282 75.8392 135.93 75.5796 135.671C75.3201 135.411 74.968 135.265 74.601 135.265C74.2339 135.265 73.8819 135.411 73.6224 135.671C73.3628 135.93 73.217 136.282 73.217 136.649C72.5158 145.608 72.1837 155.979 78.8728 162.935C80.1092 164.218 82.0652 162.262 80.8288 160.97C74.7117 154.622 75.3483 144.778 75.985 136.649H73.217C73.7983 142.775 75.1638 149.566 79.897 153.967C82.7295 156.578 86.3555 157.501 89.8707 158.857C93.6397 160.458 97.2664 162.374 100.712 164.587C103.572 166.303 108.462 169.523 106.663 173.592C105.952 175.206 108.342 176.618 109.053 174.985V174.948Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M111.903 171.137C110.759 164.59 111.442 157.854 113.878 151.67C115.972 145.968 118.722 139.823 116.646 133.715L114.118 134.785C118.896 142.128 120.992 150.895 120.05 159.604C119.857 161.376 122.634 161.367 122.818 159.604C123.775 150.399 121.547 141.145 116.507 133.383C115.695 132.137 113.444 132.894 113.979 134.453C116.083 140.552 112.66 146.789 110.704 152.463C108.551 158.688 108.071 165.37 109.311 171.839C109.643 173.582 112.309 172.844 111.977 171.101L111.903 171.137Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M107.798 173.232C102.262 172.309 97.1229 174.717 92.9341 178.26C90.9563 180.09 89.1067 182.054 87.3982 184.138C85.8666 185.844 83.7077 188.086 81.1981 187.616L80.829 190.338C86.8908 191.085 93.7921 191.574 98.7652 187.33C103.24 183.51 104.043 177.125 108.158 173.038L105.98 172.761C109.376 178.113 112.974 183.584 118.187 187.33C120.856 189.205 123.918 190.447 127.139 190.962C130.361 191.477 133.657 191.251 136.778 190.301C140.446 189.222 143.864 187.429 146.835 185.023C147.024 184.828 147.152 184.582 147.202 184.315C147.253 184.048 147.225 183.772 147.121 183.521C147.017 183.27 146.842 183.055 146.618 182.902C146.393 182.75 146.129 182.666 145.857 182.661C139.43 183.22 132.96 182.307 126.939 179.99C120.919 177.674 115.505 174.015 111.11 169.292C109.902 167.982 107.946 169.947 109.154 171.257C113.799 176.235 119.511 180.096 125.861 182.55C132.211 185.005 139.035 185.989 145.82 185.429L144.842 183.067C138.919 187.764 130.458 190.209 123.252 187.081C116.434 184.119 112.18 177.402 108.333 171.396C108.225 171.215 108.078 171.061 107.902 170.945C107.727 170.829 107.527 170.755 107.318 170.727C107.11 170.7 106.897 170.72 106.698 170.786C106.498 170.852 106.316 170.963 106.165 171.11C102.244 174.994 101.321 180.853 97.4827 184.737C93.1924 189.083 86.3003 188.271 80.7921 187.597C79.2513 187.413 78.8914 190.033 80.423 190.365C86.0788 191.408 89.2897 185.826 92.6758 182.357C96.3663 178.574 101.515 175.086 107.069 175.981C108.813 176.258 109.56 173.592 107.798 173.306V173.232Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                </>
            )}
            
            {/* Body Outline */}
            <path
                d='M62.6713 218.1C71.5886 218.1 78.8176 210.871 78.8176 201.954C78.8176 193.037 71.5886 185.808 62.6713 185.808C53.754 185.808 46.525 193.037 46.525 201.954C46.525 210.871 53.754 218.1 62.6713 218.1Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M85.7375 207.951C94.6548 207.951 101.884 200.722 101.884 191.805C101.884 182.887 94.6548 175.658 85.7375 175.658C76.8201 175.658 69.5912 182.887 69.5912 191.805C69.5912 200.722 76.8201 207.951 85.7375 207.951Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M67.2846 231.017C76.2019 231.017 83.4309 223.788 83.4309 214.871C83.4309 205.954 76.2019 198.725 67.2846 198.725C58.3672 198.725 51.1383 205.954 51.1383 214.871C51.1383 223.788 58.3672 231.017 67.2846 231.017Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M93.1186 231.017C102.036 231.017 109.265 223.788 109.265 214.871C109.265 205.954 102.036 198.725 93.1186 198.725C84.2012 198.725 76.9723 205.954 76.9723 214.871C76.9723 223.788 84.2012 231.017 93.1186 231.017Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M112.494 203.338C121.412 203.338 128.64 196.109 128.64 187.191C128.64 178.274 121.412 171.045 112.494 171.045C103.577 171.045 96.3479 178.274 96.3479 187.191C96.3479 196.109 103.577 203.338 112.494 203.338Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M135.56 204.26C144.478 204.26 151.706 197.031 151.706 188.114C151.706 179.197 144.478 171.968 135.56 171.968C126.643 171.968 119.414 179.197 119.414 188.114C119.414 197.031 126.643 204.26 135.56 204.26Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M149.4 227.326C158.317 227.326 165.546 220.098 165.546 211.18C165.546 202.263 158.317 195.034 149.4 195.034C140.483 195.034 133.254 202.263 133.254 211.18C133.254 220.098 140.483 227.326 149.4 227.326Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M132.792 227.326C141.71 227.326 148.939 220.098 148.939 211.18C148.939 202.263 141.71 195.034 132.792 195.034C123.875 195.034 116.646 202.263 116.646 211.18C116.646 220.098 123.875 227.326 132.792 227.326Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M105.113 225.481C114.03 225.481 121.259 218.252 121.259 209.335C121.259 200.418 114.03 193.189 105.113 193.189C96.1956 193.189 88.9667 200.418 88.9667 209.335C88.9667 218.252 96.1956 225.481 105.113 225.481Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M111.572 242.089C120.489 242.089 127.718 234.86 127.718 225.943C127.718 217.025 120.489 209.796 111.572 209.796C102.654 209.796 95.4253 217.025 95.4253 225.943C95.4253 234.86 102.654 242.089 111.572 242.089Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M78.3564 255.006C87.2737 255.006 94.5026 247.777 94.5026 238.86C94.5026 229.942 87.2737 222.713 78.3564 222.713C69.439 222.713 62.2101 229.942 62.2101 238.86C62.2101 247.777 69.439 255.006 78.3564 255.006Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M109.726 255.006C118.644 255.006 125.873 247.777 125.873 238.86C125.873 229.942 118.644 222.713 109.726 222.713C100.809 222.713 93.58 229.942 93.58 238.86C93.58 247.777 100.809 255.006 109.726 255.006Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M132.792 255.006C141.71 255.006 148.939 247.777 148.939 238.86C148.939 229.942 141.71 222.713 132.792 222.713C123.875 222.713 116.646 229.942 116.646 238.86C116.646 247.777 123.875 255.006 132.792 255.006Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M149.4 255.006C158.317 255.006 165.546 247.777 165.546 238.86C165.546 229.942 158.317 222.713 149.4 222.713C140.483 222.713 133.254 229.942 133.254 238.86C133.254 247.777 140.483 255.006 149.4 255.006Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M89.428 249.47C98.3454 249.47 105.574 242.241 105.574 233.324C105.574 224.406 98.3454 217.177 89.428 217.177C80.5107 217.177 73.2817 224.406 73.2817 233.324C73.2817 242.241 80.5107 249.47 89.428 249.47Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M128.179 251.315C137.096 251.315 144.325 244.086 144.325 235.169C144.325 226.252 137.096 219.023 128.179 219.023C119.262 219.023 112.033 226.252 112.033 235.169C112.033 244.086 119.262 251.315 128.179 251.315Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M74.6657 272.536C83.583 272.536 90.812 265.307 90.812 256.39C90.812 247.473 83.583 240.244 74.6657 240.244C65.7483 240.244 58.5194 247.473 58.5194 256.39C58.5194 265.307 65.7483 272.536 74.6657 272.536Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M99.5772 277.149C108.495 277.149 115.723 269.92 115.723 261.003C115.723 252.086 108.495 244.857 99.5772 244.857C90.6598 244.857 83.4309 252.086 83.4309 261.003C83.4309 269.92 90.6598 277.149 99.5772 277.149Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M117.107 277.149C126.025 277.149 133.254 269.92 133.254 261.003C133.254 252.086 126.025 244.857 117.107 244.857C108.19 244.857 100.961 252.086 100.961 261.003C100.961 269.92 108.19 277.149 117.107 277.149Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M133.715 277.149C142.632 277.149 149.861 269.92 149.861 261.003C149.861 252.086 142.632 244.857 133.715 244.857C124.798 244.857 117.569 252.086 117.569 261.003C117.569 269.92 124.798 277.149 133.715 277.149Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M149.4 277.149C158.317 277.149 165.546 269.92 165.546 261.003C165.546 252.086 158.317 244.857 149.4 244.857C140.483 244.857 133.254 252.086 133.254 261.003C133.254 269.92 140.483 277.149 149.4 277.149Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M166.007 231.017C174.925 231.017 182.154 223.788 182.154 214.871C182.154 205.954 174.925 198.725 166.007 198.725C157.09 198.725 149.861 205.954 149.861 214.871C149.861 223.788 157.09 231.017 166.007 231.017Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M163.24 260.542C172.157 260.542 179.386 253.313 179.386 244.396C179.386 235.478 172.157 228.249 163.24 228.249C154.322 228.249 147.093 235.478 147.093 244.396C147.093 253.313 154.322 260.542 163.24 260.542Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M163.24 282.685C172.157 282.685 179.386 275.456 179.386 266.539C179.386 257.622 172.157 250.393 163.24 250.393C154.322 250.393 147.093 257.622 147.093 266.539C147.093 275.456 154.322 282.685 163.24 282.685Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M158.626 210.719C167.544 210.719 174.773 203.49 174.773 194.573C174.773 185.655 167.544 178.427 158.626 178.427C149.709 178.427 142.48 185.655 142.48 194.573C142.48 203.49 149.709 210.719 158.626 210.719Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M58.9808 242.089C67.8981 242.089 75.127 234.86 75.127 225.943C75.127 217.025 67.8981 209.796 58.9808 209.796C50.0634 209.796 42.8345 217.025 42.8345 225.943C42.8345 234.86 50.0634 242.089 58.9808 242.089Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M67.2846 267C76.2019 267 83.4309 259.771 83.4309 250.854C83.4309 241.937 76.2019 234.708 67.2846 234.708C58.3672 234.708 51.1383 241.937 51.1383 250.854C51.1383 259.771 58.3672 267 67.2846 267Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M80.2016 289.144C89.1189 289.144 96.3479 281.915 96.3479 272.997C96.3479 264.08 89.1189 256.851 80.2016 256.851C71.2842 256.851 64.0553 264.08 64.0553 272.997C64.0553 281.915 71.2842 289.144 80.2016 289.144Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M101.422 306.674C110.34 306.674 117.569 299.445 117.569 290.528C117.569 281.61 110.34 274.381 101.422 274.381C92.5051 274.381 85.2761 281.61 85.2761 290.528C85.2761 299.445 92.5051 306.674 101.422 306.674Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M125.411 309.442C134.329 309.442 141.557 302.213 141.557 293.296C141.557 284.378 134.329 277.149 125.411 277.149C116.494 277.149 109.265 284.378 109.265 293.296C109.265 302.213 116.494 309.442 125.411 309.442Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />
            <path
                d='M148.477 302.983C157.395 302.983 164.624 295.755 164.624 286.837C164.624 277.92 157.395 270.691 148.477 270.691C139.56 270.691 132.331 277.92 132.331 286.837C132.331 295.755 139.56 302.983 148.477 302.983Z'
                stroke={outlineStrokeColor}
                strokeWidth='6'
                strokeLinejoin='round'
            />

            {/* Body */}
            <path
                d='M62.6713 218.1C71.5886 218.1 78.8176 210.871 78.8176 201.954C78.8176 193.037 71.5886 185.808 62.6713 185.808C53.754 185.808 46.525 193.037 46.525 201.954C46.525 210.871 53.754 218.1 62.6713 218.1Z'
                fill={palette.body}
            />
            <path
                d='M85.7375 207.951C94.6548 207.951 101.884 200.722 101.884 191.805C101.884 182.887 94.6548 175.658 85.7375 175.658C76.8201 175.658 69.5912 182.887 69.5912 191.805C69.5912 200.722 76.8201 207.951 85.7375 207.951Z'
                fill={palette.body}
            />
            <path
                d='M67.2846 231.017C76.2019 231.017 83.4309 223.788 83.4309 214.871C83.4309 205.954 76.2019 198.725 67.2846 198.725C58.3672 198.725 51.1383 205.954 51.1383 214.871C51.1383 223.788 58.3672 231.017 67.2846 231.017Z'
                fill={palette.body}
            />
            <path
                d='M93.1186 231.017C102.036 231.017 109.265 223.788 109.265 214.871C109.265 205.954 102.036 198.725 93.1186 198.725C84.2012 198.725 76.9723 205.954 76.9723 214.871C76.9723 223.788 84.2012 231.017 93.1186 231.017Z'
                fill={palette.body}
            />
            <path
                d='M112.494 203.338C121.412 203.338 128.64 196.109 128.64 187.191C128.64 178.274 121.412 171.045 112.494 171.045C103.577 171.045 96.3479 178.274 96.3479 187.191C96.3479 196.109 103.577 203.338 112.494 203.338Z'
                fill={palette.body}
            />
            <path
                d='M135.56 204.26C144.478 204.26 151.706 197.031 151.706 188.114C151.706 179.197 144.478 171.968 135.56 171.968C126.643 171.968 119.414 179.197 119.414 188.114C119.414 197.031 126.643 204.26 135.56 204.26Z'
                fill={palette.body}
            />
            <path
                d='M149.4 227.326C158.317 227.326 165.546 220.098 165.546 211.18C165.546 202.263 158.317 195.034 149.4 195.034C140.483 195.034 133.254 202.263 133.254 211.18C133.254 220.098 140.483 227.326 149.4 227.326Z'
                fill={palette.body}
            />
            <path
                d='M132.792 227.326C141.71 227.326 148.939 220.098 148.939 211.18C148.939 202.263 141.71 195.034 132.792 195.034C123.875 195.034 116.646 202.263 116.646 211.18C116.646 220.098 123.875 227.326 132.792 227.326Z'
                fill={palette.body}
            />
            <path
                d='M105.113 225.481C114.03 225.481 121.259 218.252 121.259 209.335C121.259 200.418 114.03 193.189 105.113 193.189C96.1956 193.189 88.9667 200.418 88.9667 209.335C88.9667 218.252 96.1956 225.481 105.113 225.481Z'
                fill={palette.body}
            />
            <path
                d='M111.572 242.089C120.489 242.089 127.718 234.86 127.718 225.943C127.718 217.025 120.489 209.796 111.572 209.796C102.654 209.796 95.4253 217.025 95.4253 225.943C95.4253 234.86 102.654 242.089 111.572 242.089Z'
                fill={palette.body}
            />
            <path
                d='M78.3564 255.006C87.2737 255.006 94.5026 247.777 94.5026 238.86C94.5026 229.942 87.2737 222.713 78.3564 222.713C69.439 222.713 62.2101 229.942 62.2101 238.86C62.2101 247.777 69.439 255.006 78.3564 255.006Z'
                fill={palette.body}
            />
            <path
                d='M109.726 255.006C118.644 255.006 125.873 247.777 125.873 238.86C125.873 229.942 118.644 222.713 109.726 222.713C100.809 222.713 93.58 229.942 93.58 238.86C93.58 247.777 100.809 255.006 109.726 255.006Z'
                fill={palette.body}
            />
            <path
                d='M132.792 255.006C141.71 255.006 148.939 247.777 148.939 238.86C148.939 229.942 141.71 222.713 132.792 222.713C123.875 222.713 116.646 229.942 116.646 238.86C116.646 247.777 123.875 255.006 132.792 255.006Z'
                fill={palette.body}
            />
            <path
                d='M149.4 255.006C158.317 255.006 165.546 247.777 165.546 238.86C165.546 229.942 158.317 222.713 149.4 222.713C140.483 222.713 133.254 229.942 133.254 238.86C133.254 247.777 140.483 255.006 149.4 255.006Z'
                fill={palette.body}
            />
            <path
                d='M89.428 249.47C98.3454 249.47 105.574 242.241 105.574 233.324C105.574 224.406 98.3454 217.177 89.428 217.177C80.5107 217.177 73.2817 224.406 73.2817 233.324C73.2817 242.241 80.5107 249.47 89.428 249.47Z'
                fill={palette.body}
            />
            <path
                d='M128.179 251.315C137.096 251.315 144.325 244.086 144.325 235.169C144.325 226.252 137.096 219.023 128.179 219.023C119.262 219.023 112.033 226.252 112.033 235.169C112.033 244.086 119.262 251.315 128.179 251.315Z'
                fill={palette.body}
            />
            <path
                d='M74.6657 272.536C83.583 272.536 90.812 265.307 90.812 256.39C90.812 247.473 83.583 240.244 74.6657 240.244C65.7483 240.244 58.5194 247.473 58.5194 256.39C58.5194 265.307 65.7483 272.536 74.6657 272.536Z'
                fill={palette.body}
            />
            <path
                d='M99.5772 277.149C108.495 277.149 115.723 269.92 115.723 261.003C115.723 252.086 108.495 244.857 99.5772 244.857C90.6598 244.857 83.4309 252.086 83.4309 261.003C83.4309 269.92 90.6598 277.149 99.5772 277.149Z'
                fill={palette.body}
            />
            <path
                d='M117.107 277.149C126.025 277.149 133.254 269.92 133.254 261.003C133.254 252.086 126.025 244.857 117.107 244.857C108.19 244.857 100.961 252.086 100.961 261.003C100.961 269.92 108.19 277.149 117.107 277.149Z'
                fill={palette.body}
            />
            <path
                d='M133.715 277.149C142.632 277.149 149.861 269.92 149.861 261.003C149.861 252.086 142.632 244.857 133.715 244.857C124.798 244.857 117.569 252.086 117.569 261.003C117.569 269.92 124.798 277.149 133.715 277.149Z'
                fill={palette.body}
            />
            <path
                d='M149.4 277.149C158.317 277.149 165.546 269.92 165.546 261.003C165.546 252.086 158.317 244.857 149.4 244.857C140.483 244.857 133.254 252.086 133.254 261.003C133.254 269.92 140.483 277.149 149.4 277.149Z'
                fill={palette.body}
            />
            <path
                d='M166.007 231.017C174.925 231.017 182.154 223.788 182.154 214.871C182.154 205.954 174.925 198.725 166.007 198.725C157.09 198.725 149.861 205.954 149.861 214.871C149.861 223.788 157.09 231.017 166.007 231.017Z'
                fill={palette.body}
            />
            <path
                d='M163.24 260.542C172.157 260.542 179.386 253.313 179.386 244.396C179.386 235.478 172.157 228.249 163.24 228.249C154.322 228.249 147.093 235.478 147.093 244.396C147.093 253.313 154.322 260.542 163.24 260.542Z'
                fill={palette.body}
            />
            <path
                d='M163.24 282.685C172.157 282.685 179.386 275.456 179.386 266.539C179.386 257.622 172.157 250.393 163.24 250.393C154.322 250.393 147.093 257.622 147.093 266.539C147.093 275.456 154.322 282.685 163.24 282.685Z'
                fill={palette.body}
            />
            <path
                d='M158.626 210.719C167.544 210.719 174.773 203.49 174.773 194.573C174.773 185.655 167.544 178.427 158.626 178.427C149.709 178.427 142.48 185.655 142.48 194.573C142.48 203.49 149.709 210.719 158.626 210.719Z'
                fill={palette.body}
            />
            <path
                d='M58.9808 242.089C67.8981 242.089 75.127 234.86 75.127 225.943C75.127 217.025 67.8981 209.796 58.9808 209.796C50.0634 209.796 42.8345 217.025 42.8345 225.943C42.8345 234.86 50.0634 242.089 58.9808 242.089Z'
                fill={palette.body}
            />
            <path
                d='M67.2846 267C76.2019 267 83.4309 259.771 83.4309 250.854C83.4309 241.937 76.2019 234.708 67.2846 234.708C58.3672 234.708 51.1383 241.937 51.1383 250.854C51.1383 259.771 58.3672 267 67.2846 267Z'
                fill={palette.body}
            />
            <path
                d='M80.2016 289.144C89.1189 289.144 96.3479 281.915 96.3479 272.997C96.3479 264.08 89.1189 256.851 80.2016 256.851C71.2842 256.851 64.0553 264.08 64.0553 272.997C64.0553 281.915 71.2842 289.144 80.2016 289.144Z'
                fill={palette.body}
            />
            <path
                d='M101.422 306.674C110.34 306.674 117.569 299.445 117.569 290.528C117.569 281.61 110.34 274.381 101.422 274.381C92.5051 274.381 85.2761 281.61 85.2761 290.528C85.2761 299.445 92.5051 306.674 101.422 306.674Z'
                fill={palette.body}
            />
            <path
                d='M125.411 309.442C134.329 309.442 141.557 302.213 141.557 293.296C141.557 284.378 134.329 277.149 125.411 277.149C116.494 277.149 109.265 284.378 109.265 293.296C109.265 302.213 116.494 309.442 125.411 309.442Z'
                fill={palette.body}
            />
            <path
                d='M148.477 302.983C157.395 302.983 164.624 295.755 164.624 286.837C164.624 277.92 157.395 270.691 148.477 270.691C139.56 270.691 132.331 277.92 132.331 286.837C132.331 295.755 139.56 302.983 148.477 302.983Z'
                fill={palette.body}
            />
            <path
                d='M127.257 295.602C136.174 295.602 143.403 288.373 143.403 279.456C143.403 270.539 136.174 263.31 127.257 263.31C118.339 263.31 111.11 270.539 111.11 279.456C111.11 288.373 118.339 295.602 127.257 295.602Z'
                fill={palette.body}
            />
            <path
                d='M115.262 292.834C124.179 292.834 131.408 285.605 131.408 276.688C131.408 267.771 124.179 260.542 115.262 260.542C106.345 260.542 99.1158 267.771 99.1158 276.688C99.1158 285.605 106.345 292.834 115.262 292.834Z'
                fill={palette.body}
            />

           

            {/* Stem */}
            <path
                d='M102.345 254.083C105.148 254.083 107.42 251.811 107.42 249.009C107.42 246.206 105.148 243.934 102.345 243.934C99.5425 243.934 97.2705 246.206 97.2705 249.009C97.2705 251.811 99.5425 254.083 102.345 254.083Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M140.173 250.393C142.976 250.393 145.248 248.121 145.248 245.318C145.248 242.516 142.976 240.244 140.173 240.244C137.371 240.244 135.099 242.516 135.099 245.318C135.099 248.121 137.371 250.393 140.173 250.393Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M116.692 260.182C117.139 261.578 117.885 262.86 118.879 263.937C119.452 264.503 120.151 264.927 120.919 265.174C121.687 265.421 122.502 265.484 123.298 265.358C126.592 264.878 128.52 261.667 128.373 258.484C128.373 258.117 128.227 257.765 127.967 257.506C127.708 257.246 127.356 257.1 126.989 257.1C126.622 257.1 126.27 257.246 126.01 257.506C125.75 257.765 125.605 258.117 125.605 258.484C125.643 258.995 125.599 259.509 125.475 260.007C125.377 260.49 125.176 260.946 124.885 261.344L124.774 261.511C124.664 261.64 124.553 261.76 124.442 261.87C124.442 261.87 124.119 262.147 124.313 262C124.211 262.083 124.092 262.147 123.981 262.221L123.63 262.415C123.39 262.544 123.759 262.415 123.464 262.479C123.214 262.562 122.958 262.627 122.698 262.673C122.984 262.673 122.56 262.673 122.541 262.673C122.409 262.682 122.277 262.682 122.145 262.673C122.422 262.673 122.034 262.673 121.997 262.673L121.619 262.562C121.849 262.636 121.499 262.498 121.471 262.489L121.139 262.286C120.881 262.129 121.222 262.387 121.019 262.193L120.742 261.935C120.65 261.843 120.567 261.741 120.484 261.649C120.401 261.557 120.539 261.75 120.392 261.511C120.244 261.271 120.106 261.077 119.976 260.846C119.836 260.595 119.709 260.337 119.598 260.071L119.515 259.887C119.515 259.997 119.515 260.007 119.515 259.887L119.349 259.462C119.235 259.123 118.996 258.839 118.681 258.668C118.366 258.497 117.999 258.451 117.651 258.54C117.297 258.639 116.997 258.874 116.815 259.194C116.634 259.513 116.586 259.892 116.683 260.247L116.692 260.182Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M108.767 170.971C97.5656 161.394 82.0098 161.284 68.2348 163.729C60.1616 165.159 52.2546 167.364 44.1814 168.757C36.1289 170.205 27.9448 170.79 19.7682 170.501C13.5588 170.215 6.07619 168.794 2.87461 162.797C2.15495 161.441 -0.234688 162.382 0.346578 163.858C2.88102 170.004 7.56826 175.016 13.5311 177.956C20.1073 181.201 27.4449 182.589 34.752 181.969C52.0239 180.862 67.6258 171.949 84.8147 170.51C92.7033 169.855 101.422 170.851 107.493 176.498C108.785 177.716 110.75 175.76 109.449 174.542C97.8885 163.719 79.7401 167.604 66.1865 171.59C51.1105 176.037 34.5305 182.837 18.8271 177.245C12.018 174.828 5.72557 170.003 3.03145 163.129L0.494177 164.19C3.37283 169.587 9.32389 171.968 15.0904 172.817C22.4716 173.905 30.3049 173.324 37.7229 172.466C46.0267 171.543 54.0352 169.541 62.1545 167.77C69.7848 166.109 77.5535 164.725 85.3959 165.177C93.1 165.62 100.915 167.844 106.866 172.937C108.213 174.081 110.178 172.134 108.822 170.971H108.767Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M110.067 173.333C112.734 160.795 128.908 158.986 139.297 158.054C142.757 157.741 146.235 157.584 149.713 157.565C152.897 157.565 156.098 157.805 159.272 157.565C162.426 157.39 165.494 156.476 168.231 154.899C170.825 153.379 173.263 151.608 175.511 149.612C180.124 145.571 183.888 139.14 181.286 132.931C180.622 131.344 178.066 131.916 178.62 133.669C180.299 139.029 178.74 144.667 175.575 149.151C172.115 154.05 166.672 157.113 161.062 158.912C148.348 163 134.601 161.099 121.84 164.956C115.566 166.856 109.542 170.104 105.408 175.299C104.31 176.673 106.257 178.648 107.364 177.255C115.945 166.487 130.707 165.657 143.347 164.642C155.6 163.655 169.643 161.69 177.55 151.116C181.397 145.977 183.242 139.177 181.24 132.931L178.574 133.669C180.936 139.306 177.098 144.658 172.927 148.154C170.717 150.068 168.297 151.724 165.712 153.09C162.828 154.426 159.66 155.038 156.486 154.871C149.835 154.7 143.181 154.922 136.557 155.536C130.43 156.218 124.092 157.095 118.464 159.752C113.204 162.225 108.61 166.736 107.392 172.577C107.023 174.311 109.689 175.049 110.058 173.306L110.067 173.333Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M109.053 174.948C112.577 166.976 102.243 162.031 96.7352 159.06C94.9389 158.081 93.0909 157.201 91.1994 156.421C89.3541 155.665 87.3889 155.13 85.5344 154.327C82.4591 152.975 79.9893 150.538 78.5961 147.481C77.0973 144.051 76.2134 140.385 75.985 136.649C75.985 136.282 75.8392 135.93 75.5796 135.671C75.3201 135.411 74.968 135.265 74.601 135.265C74.2339 135.265 73.8819 135.411 73.6224 135.671C73.3628 135.93 73.217 136.282 73.217 136.649C72.5158 145.608 72.1837 155.979 78.8728 162.935C80.1092 164.218 82.0652 162.262 80.8288 160.97C74.7117 154.622 75.3483 144.778 75.985 136.649H73.217C73.7983 142.775 75.1638 149.566 79.897 153.967C82.7295 156.578 86.3555 157.501 89.8707 158.857C93.6397 160.458 97.2664 162.374 100.712 164.587C103.572 166.303 108.462 169.523 106.663 173.592C105.952 175.206 108.342 176.618 109.053 174.985V174.948Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M111.903 171.137C110.759 164.59 111.442 157.854 113.878 151.67C115.972 145.968 118.722 139.823 116.646 133.715L114.118 134.785C118.896 142.128 120.992 150.895 120.05 159.604C119.857 161.376 122.634 161.367 122.818 159.604C123.775 150.399 121.547 141.145 116.507 133.383C115.695 132.137 113.444 132.894 113.979 134.453C116.083 140.552 112.66 146.789 110.704 152.463C108.551 158.688 108.071 165.37 109.311 171.839C109.643 173.582 112.309 172.844 111.977 171.101L111.903 171.137Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M107.798 173.232C102.262 172.309 97.1229 174.717 92.9341 178.26C90.9563 180.09 89.1067 182.054 87.3982 184.138C85.8666 185.844 83.7077 188.086 81.1981 187.616L80.829 190.338C86.8908 191.085 93.7921 191.574 98.7652 187.33C103.24 183.51 104.043 177.125 108.158 173.038L105.98 172.761C109.376 178.113 112.974 183.584 118.187 187.33C120.856 189.205 123.918 190.447 127.139 190.962C130.361 191.477 133.657 191.251 136.778 190.301C140.446 189.222 143.864 187.429 146.835 185.023C147.024 184.828 147.152 184.582 147.202 184.315C147.253 184.048 147.225 183.772 147.121 183.521C147.017 183.27 146.842 183.055 146.618 182.902C146.393 182.75 146.129 182.666 145.857 182.661C139.43 183.22 132.96 182.307 126.939 179.99C120.919 177.674 115.505 174.015 111.11 169.292C109.902 167.982 107.946 169.947 109.154 171.257C113.799 176.235 119.511 180.096 125.861 182.55C132.211 185.005 139.035 185.989 145.82 185.429L144.842 183.067C138.919 187.764 130.458 190.209 123.252 187.081C116.434 184.119 112.18 177.402 108.333 171.396C108.225 171.215 108.078 171.061 107.902 170.945C107.727 170.829 107.527 170.755 107.318 170.727C107.11 170.7 106.897 170.72 106.698 170.786C106.498 170.852 106.316 170.963 106.165 171.11C102.244 174.994 101.321 180.853 97.4827 184.737C93.1924 189.083 86.3003 188.271 80.7921 187.597C79.2513 187.413 78.8914 190.033 80.423 190.365C86.0788 191.408 89.2897 185.826 92.6758 182.357C96.3663 178.574 101.515 175.086 107.069 175.981C108.813 176.258 109.56 173.592 107.798 173.306V173.232Z'
                fill={palette.bodyBorder}
            />
            {displaySign && (
                <>
                    {/* Sign Image */}
                    <rect
                        x='47.0781'
                        y='1.61497'
                        width='180'
                        height='115'
                        transform='rotate(4.5795 47.0781 1.61497)'
                        fill={palette.sign}
                        stroke={palette.signBorder}
                        strokeWidth='3'
                    />
                    {/* Sign Content */}
                    <foreignObject
                        x='47.0781'
                        y='1.61497'
                        width='180'
                        height='115'
                        transform='rotate(4.5795 47.0781 1.61497)'
                    >
                        <div>
                            <p>{signText}</p>
                        </div>
                    </foreignObject>
                    {/* Left Arm */}
                        <path
                            d='M170.637 192.169C170.628 188.267 172.228 184.569 173.886 181.026C181.791 163.804 190.282 146.869 199.359 130.219'
                            fill={palette.hands}
                        />
                        <path
                            d='M172.133 192.285C172.202 187.887 174.205 183.909 176.012 179.987C177.82 176.065 179.704 172.068 181.604 168.123C185.438 160.135 189.394 152.213 193.471 144.357C195.822 139.905 198.17 135.473 200.518 131.061C201.439 129.387 198.975 127.662 198.052 129.356C189.393 145.255 181.252 161.426 173.629 177.867C171.545 182.37 169.143 186.978 169.072 192.048C169.043 193.971 172.033 194.223 172.063 192.279L172.133 192.285Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M199.268 130.613C197.433 130.351 196.233 128.623 195.252 127.043C194.342 125.858 193.835 124.413 193.805 122.919C193.941 121.164 195.387 119.842 196.72 118.691C197.722 117.733 198.855 116.923 200.086 116.284C201.318 115.738 202.675 115.532 204.014 115.689C205.353 115.845 206.626 116.358 207.7 117.173C208.694 117.965 209.468 118.999 209.948 120.176C210.427 121.353 210.597 122.633 210.44 123.895C210.257 125.146 209.751 126.329 208.97 127.324C208.189 128.319 207.161 129.093 205.989 129.568C205.143 129.87 204.262 130.062 203.367 130.138C202.033 130.274 200.689 130.274 199.355 130.139'
                            fill={palette.hands}
                        />
                        <path
                            d='M199.384 129.118C198.296 128.923 197.484 127.747 196.926 126.922C196.255 125.867 195.318 124.631 195.285 123.375C195.252 122.118 196.399 121.033 197.292 120.24C198.311 119.229 199.472 118.372 200.739 117.698C201.902 117.179 203.201 117.048 204.444 117.325C205.687 117.602 206.808 118.272 207.641 119.235C208.435 120.167 208.91 121.328 208.996 122.55C209.082 123.771 208.775 124.988 208.119 126.022C206.223 128.884 202.574 128.983 199.478 128.684C197.554 128.535 197.322 131.526 199.247 131.675C203.229 132.063 207.569 131.576 210.226 128.221C211.35 126.743 211.974 124.945 212.007 123.088C212.041 121.232 211.482 119.412 210.412 117.895C209.327 116.377 207.791 115.238 206.023 114.641C204.255 114.043 202.344 114.017 200.56 114.565C198.693 115.268 196.996 116.357 195.579 117.76C194.05 118.886 192.944 120.493 192.437 122.322C192.006 124.535 193.185 126.532 194.341 128.316C195.497 130.101 196.948 131.698 199.193 132.102C199.59 132.13 199.981 132.001 200.282 131.743C200.584 131.484 200.771 131.118 200.805 130.722C200.828 130.327 200.697 129.939 200.439 129.638C200.182 129.338 199.818 129.149 199.425 129.111L199.384 129.118Z'
                            fill={palette.arms}
                        />
                    {/* Right Arm */}
                        <path
                            d='M74.6912 187.454C72.4674 184.247 71.6842 180.295 71.0368 176.437C67.7621 157.773 65.1325 139.011 63.1479 120.152'
                            fill={palette.hands}
                        />
                        <path
                            d='M75.9877 186.7C73.5471 183.041 72.9365 178.629 72.1968 174.374C71.4572 170.119 70.7378 165.76 70.0609 161.434C68.6797 152.681 67.4366 143.914 66.3314 135.133C65.7374 130.134 65.1534 125.152 64.5795 120.187C64.3875 118.286 61.3794 118.266 61.5815 120.184C63.4851 138.188 65.9682 156.121 69.0309 173.983C69.8734 178.872 70.5133 184.03 73.334 188.243C74.4031 189.842 77.0064 188.351 75.9272 186.735L75.9877 186.7Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M63.2971 120.528C61.6376 121.355 59.6684 120.614 57.9633 119.871C56.5414 119.412 55.3036 118.511 54.4308 117.298C53.5459 115.777 53.9852 113.867 54.4286 112.163C54.7095 110.805 55.1819 109.494 55.8314 108.27C56.536 107.12 57.5359 106.181 58.727 105.549C59.9181 104.917 61.2568 104.616 62.6036 104.678C63.8716 104.765 65.0959 105.176 66.1592 105.872C67.2225 106.569 68.0891 107.526 68.6761 108.654C69.2369 109.788 69.4913 111.049 69.4139 112.311C69.3366 113.574 68.9302 114.794 68.2351 115.851C67.7104 116.58 67.0938 117.238 66.4007 117.809C65.38 118.678 64.2738 119.442 63.0989 120.088'
                            fill={palette.hands}
                        />
                        <path
                            d='M62.5429 119.231C61.5368 119.689 60.2007 119.182 59.2726 118.82C58.1215 118.333 56.6479 117.848 55.9071 116.833C55.1662 115.817 55.4942 114.273 55.7793 113.112C56.0432 111.702 56.5125 110.337 57.1722 109.063C57.8347 107.975 58.8297 107.13 60.01 106.652C61.1903 106.174 62.4932 106.088 63.7259 106.408C64.9089 106.724 65.9597 107.41 66.7242 108.366C67.4887 109.323 67.9267 110.499 67.974 111.723C68.0388 115.156 65.0921 117.309 62.3736 118.821C60.7053 119.791 62.2136 122.385 63.8819 121.414C67.3798 119.472 70.6758 116.606 70.9569 112.336C71.0426 110.481 70.5353 108.647 69.5084 107.1C68.4816 105.552 66.9885 104.372 65.2459 103.731C63.4901 103.098 61.5798 103.033 59.7852 103.546C57.9907 104.058 56.4025 105.122 55.2455 106.586C54.1086 108.225 53.3299 110.085 52.9604 112.045C52.3423 113.84 52.3441 115.79 52.9657 117.583C53.8672 119.65 55.9721 120.624 57.9369 121.436C59.9017 122.248 62.003 122.738 64.0808 121.796C64.4227 121.594 64.6713 121.266 64.7728 120.882C64.8743 120.498 64.8206 120.09 64.6232 119.745C64.4178 119.407 64.0895 119.162 63.7072 119.061C63.3248 118.96 62.9181 119.01 62.5725 119.203L62.5429 119.231Z'
                            fill={palette.arms}
                        />
                </>
            )}
        </svg>
    );
}

export default Raspberry;
