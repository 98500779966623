import './NotFound.scss'
import lemons404Image from '../../assets/404_Image.svg'
import React from 'react';
import {useNavigate} from 'react-router-dom';

const NotFound = (): React.ReactElement => {
    const navigate = useNavigate();
    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        navigate('/');
    }

    return (
        <>
            <div className='not-found__wrapper'>
                <img src={lemons404Image} alt={'OOPS'}/>
                <h2>When life gives you these <br/>lemons, restart the jam</h2>
                <button className='button--primary' onClick={onClick}>
                    Restart Jam
                </button>
            </div>
        </>
    )
}
export default NotFound;