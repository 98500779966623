import { Identify, identify } from '@amplitude/analytics-browser';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import CreateRoom from './pages/CreateRoom/CreateRoom';
import NotFound from './pages/NotFound/NotFound';
import TeamRoom from './pages/TeamRoom/TeamRoom';

function App() {

    useEffect(() => {
        // @ts-expect-error: mouseflow should be attached in index.html
        const mouseflowSessionId = window.mouseflow ? window.mouseflow.getSessionId() : undefined;
        
        if(mouseflowSessionId) {
            identify(
                new Identify()
                    .set('mouseflow session id', mouseflowSessionId)
                    .set('screen height', window.innerHeight)
                    .set('screen width', window.innerWidth)
            );
        }

    // @ts-expect-error: mouseflow should be attached in index.html
    }, [window.mouseflow]);


    return (
        <>
            <div className='page-container'>
                <Header/>
                <main className='page-content'>
                    <Routes>
                        <Route path='/' element={<CreateRoom />} />
                        <Route path=':roomId' element={<TeamRoom />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </main>
            </div>
        </>
    );
}

export default App;
