import SelectAvatar from './SelectAvatar/SelectAvatar';
import TeamVotes from './TeamVotes/TeamVotes';
import React, {useEffect} from 'react';
import {CreateParticipantRequest} from '../../types/participant';
import ApiClient from '../../services/ApiClient';
import {useNavigate, useParams} from 'react-router-dom';
import UserSessionData from '../../types/UserSessionData';
import {useRecoilState} from 'recoil';
import {userState} from '../../state/UserState';
import {roomState} from '../../state/RoomState';

const TeamRoom = (): React.ReactElement => {
    const [user, setUser] = useRecoilState(userState);
    const [room, setRoom] = useRecoilState(roomState);
    const {roomId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionStorageKey = `storyjam_participant_uuid_${roomId}`;
        const roomParticipantIds = room?.participants.map((participant) => participant.id);
        if (user) {
            const roomContainsParticipant = roomParticipantIds?.includes(user.publicId);
            if (!roomContainsParticipant) {
                window.sessionStorage.removeItem(sessionStorageKey);
                setUser(undefined);
                navigate('/');
            }
        } else {
            const currentSession = window.sessionStorage.getItem(sessionStorageKey);
            if (currentSession) {
                const userInfo = JSON.parse(currentSession);
                setUser(userInfo);
            }
        }
    }, [room]);

    useEffect(() => {
        if (roomId) {
            const timer = setInterval(() => {
                ApiClient.getRoom(roomId).then((room) => {
                    setRoom(room);
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [roomId]);


    const createParticipant = async (participantRequest: CreateParticipantRequest): Promise<void> => {
        if (roomId) {
            if (room.id === '') {
                await ApiClient.createRoom(roomId);
            }
            return callCreateParticipant(roomId, participantRequest);
        }
        return Promise.resolve();
    }

    const callCreateParticipant = (roomId: string, participantRequest: CreateParticipantRequest): Promise<void> => {
        return ApiClient.createParticipant(roomId, participantRequest)
            .then((response: UserSessionData) => {
                    setUser(response);
                    window.sessionStorage.setItem(
                        `storyjam_participant_uuid_${roomId}`,
                        JSON.stringify(response)
                    );
                }
            );
    }

    const updateTopic = (topic: string): void => {
        if (room) {
            setRoom({...room, topic: topic});
            ApiClient.updateTopic(room.id, topic);
        }
    };

    const setVote = (vote?: string): void => {
        if (roomId && user) {
            ApiClient.updateParticipantVote(roomId, user.privateId, vote);
        }
    };

    const setDisplayVotes = (displayVotes: boolean): Promise<void> => {
        if (roomId) {
            return ApiClient.showVotes(roomId, displayVotes);
        }
        return Promise.resolve();
    };

    const isVoter = (): boolean => {
        if (!room || !user) return false;
        return room.participants.some(
            (participant) => participant.id === user.publicId && participant.voter
        );
    };

    const deleteAllVotesInRoom = (): void => {
        if (roomId) {
            ApiClient.deleteAllVotesInRoom(roomId);
        }
    };

    const deleteParticipant = (publicId: string): void => {
        if (roomId) {
            ApiClient.deleteParticipant(roomId, publicId);
        }
    };

    function endSession() {
        if (roomId && user) {
            ApiClient.resetRoom(roomId, user.privateId);
        }
    }

    return (
        <>
            {!user ? (
                <SelectAvatar createParticipant={createParticipant}/>
            ) : room ? (
                <TeamVotes
                    room={room}
                    setTopic={updateTopic}
                    setDisplayVotes={setDisplayVotes}
                    setVote={setVote}
                    isVoter={isVoter()}
                    deleteAllVotesInRoom={deleteAllVotesInRoom}
                    deleteParticipant={deleteParticipant}
                    endSession={endSession}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default TeamRoom;
