import React, {useEffect, useState} from 'react';
import {Room} from '../../../types/room';
import './TeamVotes.scss';
import ParticipantComponent from './ParticipantComponent';
import Tooltip from '@mui/material/Tooltip';
import {AxiosError} from 'axios';
import Sidebar from './Sidebar';

interface TeamVotesProps {
    room: Room;
    setTopic: (topic: string) => void;
    setDisplayVotes: (displayVotes: boolean) => Promise<void>;
    setVote: (vote?: string) => void;
    isVoter: boolean;
    deleteAllVotesInRoom: () => void;
    deleteParticipant: (publicId: string) => void;
    endSession?: () => void;
}

const TeamVotes = ({
                       room,
                       setTopic,
                       setDisplayVotes,
                       setVote,
                       isVoter,
                       deleteAllVotesInRoom,
                       deleteParticipant,
                       endSession,
                   }: TeamVotesProps): React.ReactElement => {

    useEffect(() => {
        document.title = 'Vote | ' + room.id + ' | StoryJam';
    }, [room.id]);

    const [isEditingTopic, setIsEditingTopic] = useState<boolean>();
    const [topicBeingEdited, setTopicBeingEdited] = useState<string>(room.topic);
    const [participantVote, setParticipantVote] = useState<string>('');

    const voters = room.participants.filter((participant) => participant.voter);
    const nonVoters = room.participants.filter((participant) => !participant.voter);

    const numberOfVotes = voters.filter((voter) => voter.vote).length;
    const anyVotesAreIn = numberOfVotes > 0;
    const allVotesAreIn = numberOfVotes === voters.length && anyVotesAreIn;

    const [tooltip, setTooltip] = useState<{ open: boolean, timeout: NodeJS.Timeout | undefined }>({
        open: false,
        timeout: undefined
    });

    const closeTooltip = () => {
        if (tooltip.timeout) {
            clearTimeout(tooltip.timeout);
        }
        setTooltip({open: false, timeout: undefined});
    };

    const openTooltip = () => {
        if (tooltip.timeout) {
            clearTimeout(tooltip.timeout);
        }
        const timeout = setTimeout(() => closeTooltip(), 2000);
        setTooltip({open: true, timeout: timeout});
    };

    const handleShowVotesClick = () => {

        setDisplayVotes(!room.displayVotes)
            .catch((error: AxiosError) => {
                const errorMessage: { [key: string]: string } = {};
                errorMessage['message'] = error.message ?? 'no message';

                if (error.response) {
                    errorMessage['description'] = 'response error';
                } else if (error.request) {
                    errorMessage['description'] = 'request error';
                } else {
                    errorMessage['description'] = 'not response or request error';
                }
            });
    }

    const voterComponents = voters.map((participant, index) => {
        return (
            <ParticipantComponent
                participant={participant}
                key={`participant-${index}`}
                showVote={room.displayVotes}
                deleteParticipant={deleteParticipant}
            />
        );
    });

    const nonVoterComponents = nonVoters.map((participant, index) => {
        return (
            <ParticipantComponent
                participant={participant}
                key={`participant-${index}`}
                showVote={room.displayVotes}
                deleteParticipant={deleteParticipant}
            />
        );
    });
    const clearVotes = () => {
        setDisplayVotes(false);
        deleteAllVotesInRoom();
        setParticipantVote('');
    }

    function handleEndSession() {
        setParticipantVote('');
        endSession?.();
    }

    return (
        <>
            <Sidebar
                showVotesEnabled={allVotesAreIn}
                clearVotes={clearVotes}
                showVotes={handleShowVotesClick}
                isDisplayingVotes={room.displayVotes}
                clearVotesEnabled={anyVotesAreIn}
                endSession={handleEndSession}
            />
            {isEditingTopic ? (
                <>
                    <form
                        className='team-votes__edit-topic-form'
                        onSubmit={(e) => {
                            e.preventDefault();
                            setIsEditingTopic(false);
                            setTopic(topicBeingEdited);
                        }}
                    >
                        <div>
                            <textarea
                                placeholder='Write the topic here'
                                data-testid='topic-input'
                                value={topicBeingEdited}
                                onChange={(event) => {
                                    setTopicBeingEdited(event.target.value);
                                }}
                                maxLength={100}
                                rows={5}
                                cols={24}
                            />
                            <div className='character-counter'>
                                {topicBeingEdited.length} / 100 Characters Max
                            </div>
                        </div>
                        <div>
                            <button
                                className='button--primary'
                                type='submit'
                                disabled={!topicBeingEdited.trim()}
                            >
                                Set Topic
                            </button>
                            <button
                                className='button--secondary'
                                type='button'
                                onClick={() => {
                                    setIsEditingTopic(false);
                                    setTopicBeingEdited(room.topic);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <label className='team-votes__topic' data-testid='topic'>
                        {room.topic}
                    </label>
                    <button
                        className='button--primary team-votes__edit-topic-button'
                        onClick={() => {
                            setIsEditingTopic(true);
                        }}
                    >
                        Edit Topic
                    </button>
                </>
            )}
            {isVoter && (
                <form
                    className='team-votes__vote-form'
                    onSubmit={(event) => {
                        event.preventDefault();
                        setVote(participantVote);
                        openTooltip();
                    }}
                >
                    <div className='team-votes__button-row'>
                        <textarea
                            data-testid='vote-input'
                            onChange={(event) => {
                                setParticipantVote(event.target.value);
                            }}
                            value={participantVote}
                            placeholder='Write your vote here'
                            maxLength={70}
                        />
                        <Tooltip
                            onClose={closeTooltip}
                            open={tooltip.open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Voted!"
                        >
                            <input
                                className='button--primary'
                                type='submit'
                                value='Vote!'
                                disabled={!participantVote.trim()}
                            />
                        </Tooltip>
                    </div>
                    <div className='character-counter'>
                        {participantVote.length} / 70 Characters Max
                    </div>
                </form>
            )}
            <div className='participants voters' data-testid='voterSection'>
                {voterComponents}
            </div>
            {nonVoters.length > 0 && (
                <div className='non-voting-section'>
                    <label className='not-voting-label'>Not Voting</label>
                    <div className='participants' data-testid='nonVoterSection'>
                        {nonVoterComponents}
                    </div>
                </div>
            )}
        </>
    );
};

export default TeamVotes;
