import './Sidebar.scss';
import {ReactComponent as ShowVotesIcon} from '../../../assets/show_votes_button_icon.svg'
import {ReactComponent as HideVotesIcon} from '../../../assets/hide_votes_button_icon.svg'
import {ReactComponent as ClearVotesIcon} from '../../../assets/clear_votes_button_icon.svg'
import {ReactComponent as EndSessionIcon} from '../../../assets/end_session_button_icon.svg';
import Tooltip from '@mui/material/Tooltip';
import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, DialogContentText, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';

const Sidebar = (
    props: {
        showVotes?: () => void;
        clearVotes?: () => void;
        endSession?: () => void;
        showVotesEnabled?: boolean;
        isDisplayingVotes?: boolean;
        clearVotesEnabled?: boolean;
    }
): React.ReactElement => {

    const {
        showVotes = () => undefined,
        clearVotes = () => undefined,
        endSession = () => undefined,
        showVotesEnabled = false,
        isDisplayingVotes = false,
        clearVotesEnabled = false,
    } = props;

    const showHideText = isDisplayingVotes ? 'Hide Votes' : 'Show Votes';
    const showHideIcon = isDisplayingVotes ? (
        <HideVotesIcon aria-hidden='true'/>
    ) : (
        <ShowVotesIcon aria-hidden='true'/>
    );

    const [endSessionDialogOpen, setEndSessionDialogOpen] = useState(false);

    function openEndSessionDialog() {
        setEndSessionDialogOpen(true);
    }


    function cancelEndSessionDialog() {
        setEndSessionDialogOpen(false);
    }

    function executeEndSession() {
        setEndSessionDialogOpen(false);
        endSession();
    }

    return (
        <section
            aria-label='Vote Utility Navigation'
            className='sidebar'
            data-testid='teamvotes-sidebar'
        >
            <Tooltip
                title='Votes will only be shown when all voting participants have submitted a vote. TIP: If a voting participant is not voting, you can remove them from the room by selecting the X icon below their name.'
                arrow
                placement='right'
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#363131',
                            fontFamily: 'Roboto',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#363131',
                        },
                    },
                }}
            >
                <div className="sidebar-button_content">
                    <button disabled={!showVotesEnabled} onClick={showVotes} title={showHideText}>
                        <div>{showHideIcon}</div>
                        <div>{showHideText}</div>
                    </button>
                </div>
            </Tooltip>

            <button disabled={!clearVotesEnabled} title='Clear Votes' onClick={clearVotes}>
                <div><ClearVotesIcon aria-hidden='true'/></div>
                <div>Clear Votes</div>
            </button>
            <Tooltip
                title='End Session removes all other participants, sets votes to hidden, and resets the topic.'
                arrow
                placement='right'
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#363131',
                            fontFamily: 'Roboto',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#363131',
                        },
                    },
                }}
            >
                <button data-testid='endSession' onClick={openEndSessionDialog}>
                    <div><EndSessionIcon arai-hidden='true'/></div>
                    <div>End Session</div>
                </button>
            </Tooltip>
            <Dialog open={endSessionDialogOpen} onClose={cancelEndSessionDialog}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to end this session for all other participants?
                    </DialogContentText>
                    <DialogActions>
                        <Button aria-label='close' className='cancel' disableRipple onClick={cancelEndSessionDialog}>
                            cancel
                        </Button>
                        <Button aria-label='reset session' className='confirm' disableRipple onClick={executeEndSession}>
                            end&nbsp;session
                        </Button>
                    </DialogActions>
                    <IconButton
                        aria-label='close'
                        disableRipple
                        onClick={cancelEndSessionDialog}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogContent>
            </Dialog>
        </section>
    );
};

export default Sidebar;