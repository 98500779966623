import {Avatars} from '../../avatars';
import {AvatarSignProps} from '../../types/avatar';
import './AvatarStyles.scss'
import React from 'react';

function Banana({
                    signText,
                    displaySign,
                    grayScale,
                    outline,
                }: AvatarSignProps): React.ReactElement {
    const palettes = {
        standard: {
            body: '#EFE39A',
            bodyBorder: 'black',
            sign: 'white',
            signBorder: 'black',
            hands: '#FFFFFD',
            arms: 'black',
        },
        grayscale: {
            body: '#B5B5B5',
            bodyBorder: '#828282',
            sign: '#B5B5B5',
            signBorder: '#828282',
            hands: '#B5B5B5',
            arms: '#828282',
        },
    };

    const palette = grayScale ? palettes.grayscale : palettes.standard;
    const outlineStrokeWidth = 20;
    const outlineStrokeColor = 'white';

    return (
        <svg
            data-testid={Avatars.BANANA.name}
            className='avatar'
            width='211'
            height='334'
            viewBox='0 0 211 339'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            {outline && (
                <>
                    {/* Body */}
                    <path
                        d='M136.257 254.832C132.855 243.972 131.691 232.534 132.836 221.211C133.981 209.888 137.411 198.915 142.918 188.955C148.897 178.096 138.471 165.474 126.707 169.395L125.784 169.7C83.3429 184.379 59.6678 230.428 72.4741 273.516C75.6597 284.239 80.9446 294.223 88.0217 302.886C95.0987 311.55 103.827 318.72 113.699 323.982C123.571 329.243 134.39 332.49 145.528 333.535C156.666 334.579 167.9 333.399 178.578 330.065L178.91 329.963C190.176 326.402 190.988 310.92 180.35 305.808C169.885 300.808 160.566 293.698 152.978 284.926C145.39 276.154 139.697 265.908 136.257 254.832V254.832Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Right Eye */}
                    <path
                        d='M85.8529 247.626C88.6555 247.626 90.9274 245.354 90.9274 242.552C90.9274 239.749 88.6555 237.477 85.8529 237.477C83.0503 237.477 80.7783 239.749 80.7783 242.552C80.7783 245.354 83.0503 247.626 85.8529 247.626Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Left Eye */}
                    <path
                        d='M123.681 243.936C126.484 243.936 128.756 241.664 128.756 238.861C128.756 236.059 126.484 233.787 123.681 233.787C120.878 233.787 118.606 236.059 118.606 238.861C118.606 241.664 120.878 243.936 123.681 243.936Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Mouth */}
                    <path
                        d='M100.199 253.725C100.646 255.121 101.393 256.403 102.386 257.48C102.96 258.047 103.659 258.47 104.427 258.717C105.194 258.964 106.009 259.027 106.806 258.901C110.099 258.421 112.028 255.211 111.88 252.028C111.88 251.66 111.734 251.308 111.475 251.049C111.215 250.789 110.863 250.644 110.496 250.644C110.129 250.644 109.777 250.789 109.518 251.049C109.258 251.308 109.112 251.66 109.112 252.028C109.151 252.539 109.107 253.053 108.983 253.55C108.885 254.033 108.683 254.489 108.393 254.888L108.282 255.054C108.171 255.183 108.06 255.303 107.95 255.414C107.95 255.414 107.627 255.69 107.82 255.543C107.719 255.626 107.599 255.69 107.488 255.764L107.138 255.958C106.898 256.087 107.267 255.958 106.972 256.023C106.721 256.105 106.465 256.17 106.206 256.216C106.492 256.216 106.067 256.216 106.049 256.216C105.917 256.226 105.784 256.226 105.652 256.216C105.929 256.216 105.542 256.216 105.505 256.216L105.126 256.106C105.357 256.179 105.006 256.041 104.979 256.032L104.647 255.829C104.388 255.672 104.73 255.93 104.527 255.737L104.25 255.478C104.158 255.386 104.075 255.284 103.992 255.192C103.908 255.1 104.047 255.294 103.899 255.054C103.752 254.814 103.613 254.62 103.484 254.389C103.343 254.139 103.217 253.88 103.106 253.614L103.023 253.43C103.023 253.541 103.023 253.55 103.023 253.43L102.857 253.006C102.742 252.666 102.504 252.382 102.189 252.211C101.874 252.04 101.506 251.994 101.159 252.083C100.813 252.192 100.524 252.431 100.352 252.749C100.179 253.068 100.138 253.441 100.236 253.79L100.199 253.725Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Tip */}
                    <path
                        d='M187.408 313.9C188.808 314.025 190.22 313.884 191.569 313.485L189.816 312.147C189.918 315.556 189.751 318.968 189.318 322.351L190.342 321.014C189.281 321.444 188.133 321.615 186.992 321.512L188.376 322.896C188.478 319.569 188.395 316.239 188.127 312.922C188.12 312.557 187.972 312.209 187.714 311.951C187.456 311.693 187.108 311.545 186.743 311.538C186.377 311.54 186.026 311.687 185.767 311.946C185.508 312.205 185.362 312.556 185.359 312.922C185.627 316.239 185.71 319.569 185.608 322.896C185.616 323.261 185.764 323.608 186.022 323.866C186.28 324.125 186.628 324.273 186.992 324.28C188.379 324.391 189.773 324.189 191.071 323.689C191.355 323.595 191.604 323.416 191.785 323.177C191.966 322.939 192.071 322.651 192.085 322.351C192.519 318.968 192.685 315.556 192.584 312.147C192.581 311.935 192.53 311.726 192.434 311.537C192.339 311.347 192.201 311.182 192.032 311.054C191.863 310.926 191.667 310.838 191.459 310.797C191.25 310.756 191.036 310.763 190.831 310.818C189.721 311.143 188.558 311.25 187.408 311.132C187.041 311.132 186.689 311.278 186.429 311.537C186.169 311.797 186.024 312.149 186.024 312.516C186.033 312.88 186.182 313.227 186.439 313.484C186.697 313.742 187.044 313.891 187.408 313.9V313.9Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    <path
                        d='M187.389 313.835C186.914 316.597 187.146 319.435 188.063 322.084C188.177 322.425 188.417 322.71 188.734 322.882C189.051 323.053 189.421 323.097 189.77 323.006C190.113 322.897 190.401 322.66 190.573 322.343C190.745 322.027 190.788 321.656 190.692 321.309C190.494 320.74 190.328 320.161 190.194 319.574C190.12 319.26 190.056 318.937 190.01 318.651C190.01 318.651 189.945 318.199 190.01 318.467L189.954 317.987C189.897 317.374 189.879 316.757 189.899 316.142C189.899 315.847 189.899 315.542 189.954 315.219C189.954 315.081 189.954 314.942 189.954 314.804C189.954 314.666 189.954 314.619 189.954 314.804C189.954 314.712 189.954 314.619 190 314.527C190.087 314.174 190.034 313.801 189.854 313.485C189.674 313.169 189.38 312.935 189.032 312.829C188.684 312.734 188.313 312.777 187.997 312.949C187.68 313.121 187.443 313.409 187.334 313.752L187.389 313.835Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Stem */}
                    <path
                        d='M137.797 170.613C140.611 169.722 143.187 168.208 145.335 166.185C147.928 163.961 150.428 161.571 152.966 159.339H151.01C153.264 161.912 155.859 164.165 158.723 166.037L159.055 163.509C152.436 165.905 146.034 168.862 139.919 172.348H141.313C139.479 171.078 137.437 170.142 135.278 169.58C134.929 169.494 134.56 169.548 134.249 169.73C133.939 169.912 133.711 170.208 133.615 170.555C133.519 170.902 133.562 171.272 133.735 171.588C133.907 171.904 134.196 172.14 134.54 172.246C136.466 172.753 138.287 173.596 139.919 174.737C140.13 174.863 140.371 174.928 140.616 174.928C140.861 174.928 141.102 174.863 141.313 174.737C147.209 171.361 153.382 168.493 159.765 166.166C160.017 166.087 160.241 165.938 160.412 165.736C160.582 165.535 160.693 165.29 160.73 165.029C160.768 164.768 160.731 164.501 160.624 164.26C160.517 164.019 160.344 163.813 160.125 163.666C157.464 161.915 155.055 159.807 152.966 157.401C152.705 157.144 152.354 157 151.988 157C151.622 157 151.27 157.144 151.01 157.401C148.657 159.514 146.323 161.654 143.951 163.749C142.008 165.665 139.65 167.107 137.059 167.965C136.715 168.072 136.426 168.308 136.254 168.623C136.081 168.939 136.038 169.31 136.134 169.657C136.23 170.004 136.457 170.299 136.768 170.482C137.078 170.664 137.448 170.717 137.797 170.632V170.613Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                </>
            )}
            {/* Body */}
            <path
                d='M136.257 254.832C132.855 243.972 131.691 232.534 132.836 221.211C133.981 209.888 137.411 198.915 142.918 188.955C148.897 178.096 138.471 165.474 126.707 169.395L125.784 169.7C83.3429 184.379 59.6678 230.428 72.4741 273.516C75.6597 284.239 80.9446 294.223 88.0217 302.886C95.0987 311.55 103.827 318.72 113.699 323.982C123.571 329.243 134.39 332.49 145.528 333.535C156.666 334.579 167.9 333.399 178.578 330.065L178.91 329.963C190.176 326.402 190.988 310.92 180.35 305.808C169.885 300.808 160.566 293.698 152.978 284.926C145.39 276.154 139.697 265.908 136.257 254.832V254.832Z'
                fill={palette.body}
            />
            {/* Body Outline */}
            <path
                d='M136.257 254.832C132.855 243.972 131.691 232.534 132.836 221.211C133.981 209.888 137.411 198.915 142.918 188.955C148.897 178.096 138.471 165.474 126.707 169.395L125.784 169.7C83.3429 184.379 59.6678 230.428 72.4741 273.516C75.6597 284.239 80.9446 294.223 88.0217 302.886C95.0987 311.55 103.827 318.72 113.699 323.982C123.571 329.243 134.39 332.49 145.528 333.535C156.666 334.579 167.9 333.399 178.578 330.065L178.91 329.963C190.176 326.402 190.988 310.92 180.35 305.808C169.885 300.808 160.566 293.698 152.978 284.926C145.39 276.154 139.697 265.908 136.257 254.832V254.832Z'
                stroke={outlineStrokeColor}
                strokeWidth='3'
                strokeLinejoin='round'
            />
            {/* Right Eye */}
            <path
                d='M85.8529 247.626C88.6555 247.626 90.9274 245.354 90.9274 242.552C90.9274 239.749 88.6555 237.477 85.8529 237.477C83.0503 237.477 80.7783 239.749 80.7783 242.552C80.7783 245.354 83.0503 247.626 85.8529 247.626Z'
                fill={palette.bodyBorder}
            />
            {/* Left Eye */}
            <path
                d='M123.681 243.936C126.484 243.936 128.756 241.664 128.756 238.861C128.756 236.059 126.484 233.787 123.681 233.787C120.878 233.787 118.606 236.059 118.606 238.861C118.606 241.664 120.878 243.936 123.681 243.936Z'
                fill={palette.bodyBorder}
            />
            {/* Mouth */}
            <path
                d='M100.199 253.725C100.646 255.121 101.393 256.403 102.386 257.48C102.96 258.047 103.659 258.47 104.427 258.717C105.194 258.964 106.009 259.027 106.806 258.901C110.099 258.421 112.028 255.211 111.88 252.028C111.88 251.66 111.734 251.308 111.475 251.049C111.215 250.789 110.863 250.644 110.496 250.644C110.129 250.644 109.777 250.789 109.518 251.049C109.258 251.308 109.112 251.66 109.112 252.028C109.151 252.539 109.107 253.053 108.983 253.55C108.885 254.033 108.683 254.489 108.393 254.888L108.282 255.054C108.171 255.183 108.06 255.303 107.95 255.414C107.95 255.414 107.627 255.69 107.82 255.543C107.719 255.626 107.599 255.69 107.488 255.764L107.138 255.958C106.898 256.087 107.267 255.958 106.972 256.023C106.721 256.105 106.465 256.17 106.206 256.216C106.492 256.216 106.067 256.216 106.049 256.216C105.917 256.226 105.784 256.226 105.652 256.216C105.929 256.216 105.542 256.216 105.505 256.216L105.126 256.106C105.357 256.179 105.006 256.041 104.979 256.032L104.647 255.829C104.388 255.672 104.73 255.93 104.527 255.737L104.25 255.478C104.158 255.386 104.075 255.284 103.992 255.192C103.908 255.1 104.047 255.294 103.899 255.054C103.752 254.814 103.613 254.62 103.484 254.389C103.343 254.139 103.217 253.88 103.106 253.614L103.023 253.43C103.023 253.541 103.023 253.55 103.023 253.43L102.857 253.006C102.742 252.666 102.504 252.382 102.189 252.211C101.874 252.04 101.506 251.994 101.159 252.083C100.813 252.192 100.524 252.431 100.352 252.749C100.179 253.068 100.138 253.441 100.236 253.79L100.199 253.725Z'
                fill={palette.bodyBorder}
            />
            {/* Tip */}
            <path
                d='M187.408 313.9C188.808 314.025 190.22 313.884 191.569 313.485L189.816 312.147C189.918 315.556 189.751 318.968 189.318 322.351L190.342 321.014C189.281 321.444 188.133 321.615 186.992 321.512L188.376 322.896C188.478 319.569 188.395 316.239 188.127 312.922C188.12 312.557 187.972 312.209 187.714 311.951C187.456 311.693 187.108 311.545 186.743 311.538C186.377 311.54 186.026 311.687 185.767 311.946C185.508 312.205 185.362 312.556 185.359 312.922C185.627 316.239 185.71 319.569 185.608 322.896C185.616 323.261 185.764 323.608 186.022 323.866C186.28 324.125 186.628 324.273 186.992 324.28C188.379 324.391 189.773 324.189 191.071 323.689C191.355 323.595 191.604 323.416 191.785 323.177C191.966 322.939 192.071 322.651 192.085 322.351C192.519 318.968 192.685 315.556 192.584 312.147C192.581 311.935 192.53 311.726 192.434 311.537C192.339 311.347 192.201 311.182 192.032 311.054C191.863 310.926 191.667 310.838 191.459 310.797C191.25 310.756 191.036 310.763 190.831 310.818C189.721 311.143 188.558 311.25 187.408 311.132C187.041 311.132 186.689 311.278 186.429 311.537C186.169 311.797 186.024 312.149 186.024 312.516C186.033 312.88 186.182 313.227 186.439 313.484C186.697 313.742 187.044 313.891 187.408 313.9V313.9Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M187.389 313.835C186.914 316.597 187.146 319.435 188.063 322.084C188.177 322.425 188.417 322.71 188.734 322.882C189.051 323.053 189.421 323.097 189.77 323.006C190.113 322.897 190.401 322.66 190.573 322.343C190.745 322.027 190.788 321.656 190.692 321.309C190.494 320.74 190.328 320.161 190.194 319.574C190.12 319.26 190.056 318.937 190.01 318.651C190.01 318.651 189.945 318.199 190.01 318.467L189.954 317.987C189.897 317.374 189.879 316.757 189.899 316.142C189.899 315.847 189.899 315.542 189.954 315.219C189.954 315.081 189.954 314.942 189.954 314.804C189.954 314.666 189.954 314.619 189.954 314.804C189.954 314.712 189.954 314.619 190 314.527C190.087 314.174 190.034 313.801 189.854 313.485C189.674 313.169 189.38 312.935 189.032 312.829C188.684 312.734 188.313 312.777 187.997 312.949C187.68 313.121 187.443 313.409 187.334 313.752L187.389 313.835Z'
                fill={palette.bodyBorder}
            />
            {/* Stem */}
            <path
                d='M137.797 170.613C140.611 169.722 143.187 168.208 145.335 166.185C147.928 163.961 150.428 161.571 152.966 159.339H151.01C153.264 161.912 155.859 164.165 158.723 166.037L159.055 163.509C152.436 165.905 146.034 168.862 139.919 172.348H141.313C139.479 171.078 137.437 170.142 135.278 169.58C134.929 169.494 134.56 169.548 134.249 169.73C133.939 169.912 133.711 170.208 133.615 170.555C133.519 170.902 133.562 171.272 133.735 171.588C133.907 171.904 134.196 172.14 134.54 172.246C136.466 172.753 138.287 173.596 139.919 174.737C140.13 174.863 140.371 174.928 140.616 174.928C140.861 174.928 141.102 174.863 141.313 174.737C147.209 171.361 153.382 168.493 159.765 166.166C160.017 166.087 160.241 165.938 160.412 165.736C160.582 165.535 160.693 165.29 160.73 165.029C160.768 164.768 160.731 164.501 160.624 164.26C160.517 164.019 160.344 163.813 160.125 163.666C157.464 161.915 155.055 159.807 152.966 157.401C152.705 157.144 152.354 157 151.988 157C151.622 157 151.27 157.144 151.01 157.401C148.657 159.514 146.323 161.654 143.951 163.749C142.008 165.665 139.65 167.107 137.059 167.965C136.715 168.072 136.426 168.308 136.254 168.623C136.081 168.939 136.038 169.31 136.134 169.657C136.23 170.004 136.457 170.299 136.768 170.482C137.078 170.664 137.448 170.717 137.797 170.632V170.613Z'
                fill={palette.bodyBorder}
            />
            {displaySign && (
                <>
                    {/* Sign Image */}
                    <rect
                        x='1.78645'
                        y='42.2638'
                        width='180'
                        height='115'
                        transform='rotate(-12.3666 1.78645 42.2638)'
                        fill={palette.sign}
                        stroke={palette.signBorder}
                        strokeWidth='3'
                    />
                    {/* Sign Content */}
                    <foreignObject
                        x='1.78645'
                        y='42.2638'
                        width='180'
                        height='115'
                        transform='rotate(-12.3666 1.78645 42.2638)'
                    >
                        <div>
                            <p>{signText}</p>
                        </div>
                    </foreignObject>
                    {/* Right Arm */}
                        <path
                            d='M76.9703 216.506C74.2237 213.734 72.7664 209.978 71.4594 206.291C64.9952 188.479 59.1493 170.458 53.9217 152.229'
                            fill={palette.hands}
                        />
                        <path
                            d='M54.1336 152.574C52.6427 153.676 50.5749 153.288 48.7667 152.852C47.2868 152.647 45.9113 151.974 44.8413 150.931C43.7058 149.587 43.807 147.63 43.9479 145.874C43.989 144.489 44.2266 143.116 44.6538 141.797C45.1481 140.543 45.9698 139.444 47.0332 138.615C48.0966 137.786 49.3627 137.257 50.6998 137.084C51.9637 136.95 53.2408 137.143 54.4088 137.644C55.5768 138.145 56.5965 138.937 57.3701 139.946C58.1193 140.965 58.5886 142.163 58.7316 143.42C58.8745 144.677 58.6861 145.949 58.1851 147.111C57.7948 147.92 57.3018 148.675 56.7183 149.357C55.8639 150.39 54.907 151.335 53.8621 152.175'
                            fill={palette.hands}
                        />
                        <path
                            d='M78.1163 215.538C75.0777 212.358 73.7108 208.12 72.2439 204.058C70.777 199.996 69.3119 195.828 67.8944 191.685C65.0153 183.305 62.2695 174.887 59.657 166.43C58.2043 161.61 56.7646 156.805 55.3377 152.015C54.8187 150.176 51.8528 150.678 52.3847 152.532C57.3841 169.933 62.9418 187.163 69.0581 204.222C70.7363 208.891 72.2616 213.859 75.7707 217.519C77.1011 218.909 79.4062 216.988 78.0628 215.583L78.1163 215.538Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M53.1662 151.428C52.2548 152.053 50.851 151.786 49.8741 151.59C48.6559 151.31 47.1205 151.088 46.2147 150.217C45.3089 149.346 45.3639 147.768 45.4432 146.575C45.4583 145.14 45.6837 143.715 46.1122 142.346C46.5759 141.159 47.4091 140.154 48.4885 139.478C49.5679 138.803 50.8362 138.492 52.1056 138.593C53.3255 138.699 54.4794 139.193 55.3983 140.002C56.3172 140.811 56.9527 141.893 57.2117 143.09C57.8713 146.46 55.343 149.092 52.9282 151.053C51.4536 152.298 53.3891 154.59 54.8637 153.345C57.9714 150.825 60.7201 147.431 60.2558 143.177C60.0183 141.335 59.2003 139.617 57.9205 138.271C56.6406 136.925 54.9655 136.022 53.1379 135.693C51.299 135.375 49.4065 135.642 47.728 136.458C46.0496 137.274 44.6702 138.597 43.7848 140.24C42.9496 142.052 42.5056 144.019 42.4818 146.013C42.1846 147.888 42.5249 149.808 43.4482 151.467C44.6948 153.346 46.9368 153.939 49.0127 154.398C51.0887 154.857 53.2431 154.975 55.1258 153.686C55.4275 153.428 55.6153 153.062 55.6487 152.666C55.6821 152.27 55.5582 151.877 55.3041 151.572C55.0431 151.275 54.6772 151.091 54.2831 151.057C53.889 151.024 53.4973 151.145 53.1903 151.394L53.1662 151.428Z'
                            fill={palette.arms}
                        />
                    {/* Left Arm */}
                        <path
                            d='M130.048 213.452C131.711 209.921 134.74 207.264 137.755 204.773C152.272 192.594 167.197 180.925 182.529 169.765'
                            fill={palette.hands}
                        />
                        <path
                            d='M182.278 170.082C180.732 169.059 180.387 166.984 180.177 165.136C179.862 163.675 180.023 162.152 180.635 160.79C181.509 159.262 183.383 158.686 185.08 158.217C186.395 157.781 187.767 157.533 189.152 157.482C190.5 157.517 191.814 157.912 192.957 158.627C194.1 159.341 195.031 160.35 195.652 161.546C196.211 162.688 196.468 163.953 196.398 165.222C196.327 166.491 195.932 167.721 195.25 168.794C194.549 169.847 193.585 170.698 192.454 171.263C191.322 171.829 190.062 172.088 188.799 172.015C187.905 171.926 187.027 171.722 186.186 171.407C184.922 170.959 183.707 170.384 182.56 169.69'
                            fill={palette.hands}
                        />
                        <path
                            d='M131.35 214.196C133.296 210.252 136.809 207.515 140.122 204.744C143.435 201.974 146.848 199.168 150.254 196.416C157.139 190.839 164.106 185.374 171.155 180.021C175.185 177.004 179.205 174.004 183.215 171.022C184.765 169.903 183.277 167.289 181.717 168.425C167.085 179.086 152.804 190.214 138.875 201.808C135.065 204.985 130.92 208.121 128.685 212.672C127.836 214.398 130.43 215.905 131.289 214.161L131.35 214.196Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M183.023 168.78C182.123 168.139 181.893 166.728 181.742 165.743C181.588 164.503 181.27 162.985 181.778 161.835C182.285 160.686 183.786 160.196 184.934 159.862C186.287 159.384 187.703 159.107 189.137 159.04C190.41 159.069 191.64 159.507 192.645 160.29C193.65 161.072 194.376 162.157 194.716 163.384C195.035 164.567 194.967 165.82 194.522 166.96C194.077 168.101 193.278 169.069 192.242 169.723C189.303 171.497 185.963 170.025 183.294 168.428C181.619 167.47 180.129 170.074 181.804 171.032C185.236 173.088 189.367 174.506 193.205 172.612C194.853 171.757 196.187 170.4 197.012 168.736C197.838 167.073 198.112 165.19 197.795 163.36C197.463 161.523 196.563 159.837 195.221 158.54C193.879 157.243 192.163 156.401 190.316 156.132C188.329 155.969 186.328 156.226 184.447 156.887C182.584 157.251 180.896 158.229 179.655 159.665C178.317 161.48 178.528 163.789 178.809 165.897C179.089 168.004 179.717 170.069 181.573 171.396C181.919 171.59 182.328 171.641 182.711 171.537C183.094 171.433 183.421 171.182 183.62 170.838C183.81 170.491 183.858 170.084 183.754 169.703C183.65 169.321 183.402 168.995 183.063 168.792L183.023 168.78Z'
                            fill={palette.arms}
                        />
                </>
            )}
        </svg>
    );
}

export default Banana;
