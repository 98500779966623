import axios from 'axios';
import { CreateParticipantRequest } from '../types/participant';
import { Room } from '../types/room';
import UserSessionData from '../types/UserSessionData';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8080';
const createRoom = async (roomId: string): Promise<void> => {
    return await axios.post(`/api/${roomId}`);
};

const getRoom = async (roomId: string): Promise<Room> => {
    return axios.get(`/api/${roomId}`).then((response) => response.data as Room);
};

const updateTopic = async (roomId: string, topic: string): Promise<void> => {
    return await axios.patch(`/api/${roomId}`, { property: 'topic', value: topic });
};

const showVotes = async (roomId: string, displayVotes: boolean): Promise<void> => {
    return await axios.patch(`/api/${roomId}`, { property: 'displayVotes', value: displayVotes });
};

const createParticipant = async (
    roomId: string,
    participantRequest: CreateParticipantRequest
): Promise<UserSessionData> => {
    return axios
        .post(`/api/${roomId}/participant`, participantRequest)
        .then((response) => response.data);
};

const updateParticipantVote = async (
    roomId: string,
    privateId: string,
    vote?: string
): Promise<void> => {
    return await axios.patch(`/api/${roomId}/participant/${privateId}`, {
        property: 'vote',
        value: vote,
    });
};

const updateParticipantAvatar = async (
    roomId: string,
    privateId: string,
    avatar: string
): Promise<void> => {
    return await axios.patch(`/api/${roomId}/participant/${privateId}`, {
        property: 'avatar',
        value: avatar,
    });
};

const updateParticipantName = async (
    roomId: string,
    privateId: string,
    name: string
): Promise<void> => {
    return await axios.patch(`/api/${roomId}/participant/${privateId}`, {
        property: 'name',
        value: name,
    });
};

const deleteParticipant = async (roomId: string, publicId: string): Promise<void> => {
    return await axios.delete(`/api/${roomId}/participant/${publicId}`);
};

const deleteAllVotesInRoom = async (roomId: string): Promise<void> => {
    return await axios.delete(`/api/${roomId}/participants/vote`);
};

const resetRoom = async (roomId: string, privateId: string): Promise<void> => {
    return await axios.patch(`/api/${roomId}/resetRoom/${privateId}`);
};

export default {
    createRoom,
    getRoom,
    updateTopic,
    showVotes,
    createParticipant,
    updateParticipantVote,
    updateParticipantAvatar,
    updateParticipantName,
    deleteParticipant,
    deleteAllVotesInRoom,
    resetRoom,
};
