import React, {useEffect, useState} from 'react';
import Api from '../../services/ApiClient';
import {useNavigate} from 'react-router-dom';
import './CreateRoom.scss';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import fruitGroup from '../../assets/fruitGroup.svg';

const CreateRoom = (): React.ReactElement => {
    const [roomId, setRoomId] = useState('');
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRoomId(event.target.value.toLowerCase());
    };

    useEffect(() => {
        document.title = 'StoryJam | Online Team Voting Tool';
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        await Api.createRoom(roomId);
        navigate(`/${roomId}`);
    };

    const roomNameIsValid = () => {
        return roomId.match('^[-a-zA-Z0-9]{1,20}$');
    };

    const showErrorState = () => {
        return !roomId.match('^[-a-zA-Z0-9]{0,20}$');
    }

    return (
        <>
            <div className='create-room'>
                <h1 className='screen-reader-only'>StoryJam an Online Team Voting Tool</h1>
                <div className='create-room__fruitAdvocates'>
                    <img src={fruitGroup} alt={'StoryJam fruit avatars'}/>
                </div>
                <div className='create-room__goals'>
                    <h2>
                        give your team a SAY.
                        <br/>
                        Make Decisions.
                        <br/>
                        Have FUN.
                    </h2>
                </div>
                <div className='create-room__description'>
                    <p>StoryJam allows your team to voice their opinions.</p>
                    <p>Simply create a room, set a topic and vote!</p>
                </div>
                <form className='create-room__form' onSubmit={onSubmit}>
                    <label htmlFor='create-room-input'>Create or Join a Room:</label>
                    <div className='create-room__form__button-row'>
                        <div>
                            <input
                                id='create-room-input'
                                type='text'
                                value={roomId}
                                onChange={handleChange}
                                className={ showErrorState() ? 'invalid' : ''}
                                maxLength={20}
                                autoFocus
                            />
                            <div className='character-counter'>
                                {roomId.length} / 20 Characters Max
                            </div>
                            <div className='create-room__helperText'>
                                <p>Please only use letters (a-z), numbers (0-9), and hyphens (-).</p>
                                {showErrorState() ? <><PriorityHighRoundedIcon aria-hidden='true'/></> : <></>}
                            </div>
                        </div>
                        <button className='button--primary'
                                disabled={!roomNameIsValid()}
                                type='submit'
                        >
                            Let&apos;s Jam
                        </button>
                    </div>
                </form>
                <div className='create-room_howTo'>
                    <h2>How to use StoryJam</h2>
                    <iframe src='https://www.youtube.com/embed/CNpWEdH5-sM?rel=0&cc_load_policy=1'
                            title='YouTube video player'
                            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            allowFullScreen
                            sandbox=''
                    >
                    </iframe>
                </div>
            </div>
        </>
    );
};

export default CreateRoom;
