import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiClient from '../services/ApiClient';
import { roomState } from '../state/RoomState';
import { userState } from '../state/UserState';
import './Header.scss';
import { IconButton, Tooltip } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

const Header = (): React.ReactElement => {
    const [user, setUser] = useRecoilState(userState);
    const room = useRecoilValue(roomState);
    const [showBackButton, setShowBackButton] = useState<boolean>(false);
    const [showCopyInvitationLink, setShowCopyInvitationLink] = useState<boolean>(true);
    const [tooltip, setToolTip] = useState<{ open: boolean; timeout: NodeJS.Timeout | undefined }>({
        open: false,
        timeout: undefined,
    });

    const navigate = useNavigate();
    const location = useLocation();
    const roomName = location.pathname.substring(1);

    useEffect(() => {
        const regexValidRoomNameCheck = /^[-a-zA-Z0-9]{1,20}$/;
        const isRoomNameValid = regexValidRoomNameCheck.test(roomName);
        if (isRoomNameValid) {
            setShowBackButton(true);
            setShowCopyInvitationLink(true);
        } else {
            setShowBackButton(false);
            setShowCopyInvitationLink(false);
        }
    }, [roomName]);

    const navigateBack = (): void => {
        if (user) {
            window.sessionStorage.removeItem(`storyjam_participant_uuid_${room.id}`);
            ApiClient.deleteParticipant(room.id, user.publicId);
            setUser(undefined);
        } else {
            navigate('/');
        }
    };

    const openToolTip = () => {
        if (tooltip.timeout) {
            clearTimeout(tooltip.timeout);
        }
        const timeout = setTimeout(() => closeToolTip(), 2000);
        setToolTip({ open: true, timeout: timeout });
    };

    const closeToolTip = () => {
        if (tooltip.timeout) {
            clearTimeout(tooltip.timeout);
        }
        setToolTip({ open: false, timeout: undefined });
    };

    return (
        <header className='header__container'>
            <div></div>
            <div className='page-header'>
                {showBackButton && (
                    <IconButton
                        className='page-header__back-button'
                        title='Back to previous page'
                        disableRipple
                        onClick={navigateBack}
                    >
                        <ArrowBackIcon aria-hidden='true' tabIndex={-1} />
                    </IconButton>
                )}
                <p className='page-header__title'>
                    StoryJam <span className='screen-reader-only'>Logo</span>
                </p>
                {showCopyInvitationLink && (
                    <Tooltip
                        onClose={closeToolTip}
                        open={tooltip.open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title='Copied!'
                    >
                        <button
                            className='page-header__copy-link-button'
                            data-testid={'copy-button'}
                            onClick={() => {
                                navigator.clipboard.writeText(window.location.href);
                                openToolTip();
                            }}
                        >
                            <ShareOutlinedIcon aria-hidden='true' tabIndex={-1} />
                            <div>
                                <p>Room: {roomName}</p>
                                <p>Share Link to the Jam!</p>
                            </div>
                        </button>
                    </Tooltip>
                )}
            </div>
        </header>
    );
};

export default Header;
