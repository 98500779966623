import React, {useEffect, useState} from 'react';
import {AvatarSelector} from './AvatarSelector';
import {CreateParticipantRequest} from '../../../types/participant';
import './SelectAvatar.scss';
import {Avatars} from '../../../avatars';
import {AxiosError} from 'axios';

const avatarList: Array<string> = Object.values(Avatars).map((avatar) => avatar.name);
const randomAvatar = avatarList[Math.floor(Math.random() * avatarList.length)];

const SelectAvatar = ({
                          createParticipant,
                      }: {
    createParticipant: (request: CreateParticipantRequest) => Promise<void>;
}): React.ReactElement => {
    const [participantRequest, setParticipantRequest] = useState<CreateParticipantRequest>({
        name: '',
        avatar: randomAvatar,
        voter: true,
    });

    const disableJoin = !participantRequest.name.trim();

    useEffect(() => {
        document.title = 'Create Profile | StoryJam';
    }, []);

    const setAvatar = (avatar: string): void => {
        setParticipantRequest((existingUserProfile) => {
            return {...existingUserProfile, avatar: avatar};
        });
    };

    const setName = (name: string): void => {
        setParticipantRequest((existingUserProfile) => {
            return {...existingUserProfile, name: name};
        });
    };

    const setVoter = (voting: boolean) => {
        setParticipantRequest((existingUserProfile) => {
            return {...existingUserProfile, voter: voting};
        });
    };

    return (
        <>
            <form
                className='select-avatar__form'
                onSubmit={(event) => {
                    event.preventDefault();

                    createParticipant(participantRequest)
                        .catch((error: AxiosError) => {
                            const errorMessage: { [key: string]: string } = {};
                            errorMessage['message'] = error.message ?? 'no message';

                            if (error.response) {
                                errorMessage['description'] = 'response error';
                            } else if (error.request) {
                                errorMessage['description'] = 'request error';
                            } else {
                                errorMessage['description'] = 'not response or request error';
                            }
                        });
                }}
            >
                <div className='step'>
                    <label htmlFor='name'>1: Enter your name</label>
                    <div>
                        <input
                            className='select-avatar__input'
                            value={participantRequest.name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            id='name'
                            required
                            maxLength={10}
                            autoFocus
                        />
                        <div className='character-counter'>
                            {participantRequest.name.length} / 10 Characters Max
                        </div>
                    </div>
                </div>
                <div className='step'>
                    <label htmlFor='avatar'>2: Pick an avatar</label>
                    <AvatarSelector avatar={participantRequest.avatar} setAvatar={setAvatar}/>
                </div>
                <div className='step'>
                    <label className='choose-type' htmlFor='participation-type'>
                        3: Choose a participation type
                    </label>
                    <div id='participation-type' className='select-avatar__voter-radios'>
                        <label>
                            <input
                                type='radio'
                                name='voter'
                                className='accent'
                                checked={participantRequest.voter}
                                onChange={() => setVoter(true)}
                            />
                            I&apos;m Voting
                        </label>
                        <label>
                            <input
                                type='radio'
                                name='voter'
                                className='accent'
                                checked={!participantRequest.voter}
                                onChange={() => setVoter(false)}
                            />
                            I&apos;m Not Voting
                        </label>
                    </div>
                </div>
                <div className='step'>
                    <div
                        className='disabled-wrapper'
                        data-testid='disabled-wrapper'
                    >
                        <button className='button--primary' disabled={disableJoin} type='submit'>
                            Join the Jam
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default SelectAvatar;
