import { Avatars } from '../../../avatars';
import { Avatar } from '../../../types/avatar';

export const AvatarSelector = ({
    avatar,
    setAvatar,
}: {
    avatar: string | undefined;
    setAvatar: (avatar: string) => void;
}) => {
    return (
        <div id='avatar' className='select-avatar'>
            {Object.values(Avatars).map((currentAvatar: Avatar) => {
                const selected = avatar === currentAvatar.name;
                const currentImage = currentAvatar.image({
                    displaySign: false,
                    grayScale: false,
                    outline: selected,
                });

                return (
                    <button
                        type='button'
                        className='select-avatar__button'
                        data-testid={selected ? 'selected avatar' : ''}
                        key={currentAvatar.name}
                        onClick={() => {
                            setAvatar(currentAvatar.name);
                        }}
                        title={`Pick ${currentAvatar.name} as your avatar`}
                    >
                        {currentImage}
                    </button>
                );
            })}
        </div>
    );
};
