import Apple from './assets/fruit/Apple';
import Banana from './assets/fruit/Banana';
import Blueberry from './assets/fruit/Blueberry';
import Orange from './assets/fruit/Orange';
import Raspberry from './assets/fruit/Raspberry';
import Strawberry from './assets/fruit/Strawberry';

export const Avatars = {
    APPLE: { name: 'apple', image: Apple },
    BANANA: { name: 'banana', image: Banana },
    BLUEBERRY: { name: 'blueberry', image: Blueberry },
    ORANGE: { name: 'orange', image: Orange },
    RASPBERRY: { name: 'raspberry', image: Raspberry },
    STRAWBERRY: { name: 'strawberry', image: Strawberry },
};
