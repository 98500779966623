import {Avatars} from '../../avatars';
import {AvatarSignProps} from '../../types/avatar';
import './AvatarStyles.scss'
import React from 'react';

function Apple({
                   signText,
                   displaySign,
                   grayScale,
                   outline,
               }: AvatarSignProps): React.ReactElement {
    const palettes = {
        standard: {
            body: '#FF4E4E',
            bodyBorder: 'black',
            sign: 'white',
            signBorder: 'black',
            hands: '#FFFFFD',
            arms: 'black',
        },
        grayscale: {
            body: '#B5B5B5',
            bodyBorder: '#828282',
            sign: '#B5B5B5',
            signBorder: '#828282',
            hands: '#B5B5B5',
            arms: '#828282',
        },
    };
    const palette = grayScale ? palettes.grayscale : palettes.standard;
    const outlineStrokeWidth = 20;
    const outlineStrokeColor = 'white';

    return (
        <svg
            data-testid={Avatars.APPLE.name}
            className='avatar'
            width='222'
            height='323'
            viewBox='0 0 222 328'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >

            {outline && (
                <>
                    {/* Body */}
                    <path
                        d='M192.903 242.397C192.903 273.37 178.141 322.86 120.014 322.86C65.5779 322.86 47.125 273.37 47.125 242.397C47.125 211.423 65.5779 186.309 120.014 186.309C176.295 186.309 192.903 211.414 192.903 242.397Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Outer Leaf */}
                    <path
                        d='M116.646 187.951C113.325 181.15 108.108 175.453 101.625 171.547C94.3916 167.164 86.0509 165.559 77.6363 166.011C72.2989 166.374 67.0039 167.208 61.813 168.502C60.3644 168.843 60.6412 170.716 61.813 171.178C64.8854 172.368 66.6292 175.671 68.8066 177.95C71.5746 180.829 75.2651 182.268 79.1033 183.107C87.2503 184.897 95.8493 184.03 103.784 186.798C107.571 187.993 110.871 190.378 113.195 193.598C114.173 195.065 116.517 193.736 115.585 192.195C113.343 188.524 111.841 184.449 111.165 180.201C110.888 178.448 108.213 179.195 108.499 180.939C109.234 185.416 110.829 189.708 113.195 193.579L115.585 192.177C111.313 185.765 103.673 183.227 96.3752 182.277C91.762 181.677 87.1488 181.594 82.5817 180.893C78.5221 180.266 74.1118 179.269 71.104 176.28C68.29 173.512 66.4078 169.951 62.5511 168.447V171.122C71.2332 169.083 80.395 167.607 89.234 169.508C94.6259 170.694 99.6777 173.091 104.008 176.516C108.338 179.941 111.833 184.306 114.228 189.28C114.994 190.885 117.384 189.483 116.618 187.887L116.646 187.951Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Inner Leaf */}
                    <path
                        d='M83.4125 175.782C94.9271 176.843 108.001 180.312 113.509 191.596C114.284 193.201 116.674 191.799 115.899 190.203C109.948 177.996 95.859 174.167 83.4125 173.014C81.641 172.848 81.6595 175.625 83.4125 175.782V175.782Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                    {/* Stem */}
                    <path
                        d='M114.856 191.845C114.159 186.132 114.984 180.336 117.246 175.044C118.359 172.461 119.804 170.036 121.546 167.829C122.429 166.664 123.428 165.592 124.526 164.627C125.089 164.157 127.358 163.059 127.201 164.627L127.884 163.437C123.24 166.68 119.458 171.007 116.864 176.042C114.271 181.078 112.945 186.67 113.002 192.334C113.002 194.115 115.77 194.115 115.77 192.334C115.714 187.507 116.748 182.73 118.794 178.357C120.841 173.985 123.847 170.131 127.589 167.081C128.447 166.389 129.831 165.836 129.969 164.627C130.011 164.078 129.91 163.528 129.675 163.03C129.441 162.532 129.081 162.103 128.632 161.785C126.519 160.189 124.018 161.269 122.302 162.865C118.683 166.334 115.9 170.58 114.165 175.284C112.129 180.551 111.416 186.238 112.089 191.845C112.301 193.589 115.069 193.617 114.856 191.845Z'
                        stroke={outlineStrokeColor}
                        strokeWidth={outlineStrokeWidth}
                        strokeLinejoin='round'
                    />
                </>
            )}
            {/* Body */}
            <path
                d='M192.903 242.397C192.903 273.37 178.141 322.86 120.014 322.86C65.5779 322.86 47.125 273.37 47.125 242.397C47.125 211.423 65.5779 186.309 120.014 186.309C176.295 186.309 192.903 211.414 192.903 242.397Z'
                fill={palette.body}
            />
            {/* Body Outline */}
            <path
                d='M192.903 242.397C192.903 273.37 178.141 322.86 120.014 322.86C65.5779 322.86 47.125 273.37 47.125 242.397C47.125 211.423 65.5779 186.309 120.014 186.309C176.295 186.309 192.903 211.414 192.903 242.397Z'
                stroke={outlineStrokeColor}
                strokeWidth='3'
                strokeLinejoin='round'
            />
            {/* Face */}
            <path
                d='M114.939 263.811C117.742 263.811 120.014 261.539 120.014 258.737C120.014 255.934 117.742 253.662 114.939 253.662C112.137 253.662 109.865 255.934 109.865 258.737C109.865 261.539 112.137 263.811 114.939 263.811Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M152.768 260.121C155.57 260.121 157.842 257.849 157.842 255.046C157.842 252.244 155.57 249.972 152.768 249.972C149.965 249.972 147.693 252.244 147.693 255.046C147.693 257.849 149.965 260.121 152.768 260.121Z'
                fill={palette.bodyBorder}
            />
            <path
                d='M129.286 269.91C129.733 271.306 130.479 272.588 131.473 273.665C132.047 274.232 132.746 274.655 133.513 274.902C134.281 275.149 135.096 275.212 135.892 275.086C139.186 274.606 141.114 271.395 140.967 268.212C140.967 267.845 140.821 267.493 140.561 267.234C140.302 266.974 139.95 266.828 139.583 266.828C139.216 266.828 138.864 266.974 138.604 267.234C138.345 267.493 138.199 267.845 138.199 268.212C138.237 268.723 138.194 269.237 138.07 269.735C137.971 270.218 137.77 270.674 137.479 271.073L137.368 271.239C137.258 271.368 137.147 271.488 137.036 271.598C137.036 271.598 136.713 271.875 136.907 271.728C136.806 271.811 136.686 271.875 136.575 271.949L136.224 272.143C135.984 272.272 136.353 272.143 136.058 272.207C135.808 272.29 135.552 272.355 135.292 272.401C135.578 272.401 135.154 272.401 135.136 272.401C135.003 272.41 134.871 272.41 134.739 272.401C135.016 272.401 134.628 272.401 134.591 272.401L134.213 272.29C134.444 272.364 134.093 272.226 134.065 272.217L133.733 272.014C133.475 271.857 133.816 272.115 133.613 271.921L133.336 271.663C133.244 271.571 133.161 271.469 133.078 271.377C132.995 271.285 133.133 271.478 132.986 271.239C132.838 270.999 132.7 270.805 132.571 270.574C132.43 270.323 132.304 270.065 132.192 269.799L132.109 269.615C132.155 269.725 132.155 269.735 132.109 269.615L131.943 269.19C131.829 268.851 131.59 268.567 131.275 268.396C130.961 268.225 130.593 268.179 130.246 268.268C129.892 268.367 129.591 268.602 129.41 268.922C129.228 269.241 129.18 269.62 129.277 269.975L129.286 269.91Z'
                fill={palette.bodyBorder}
            />
            {/* Outer Leaf */}
            <path
                d='M116.646 187.951C113.325 181.15 108.108 175.453 101.625 171.547C94.3916 167.164 86.0509 165.559 77.6363 166.011C72.2989 166.374 67.0039 167.208 61.813 168.502C60.3644 168.843 60.6412 170.716 61.813 171.178C64.8854 172.368 66.6292 175.671 68.8066 177.95C71.5746 180.829 75.2651 182.268 79.1033 183.107C87.2503 184.897 95.8493 184.03 103.784 186.798C107.571 187.993 110.871 190.378 113.195 193.598C114.173 195.065 116.517 193.736 115.585 192.195C113.343 188.524 111.841 184.449 111.165 180.201C110.888 178.448 108.213 179.195 108.499 180.939C109.234 185.416 110.829 189.708 113.195 193.579L115.585 192.177C111.313 185.765 103.673 183.227 96.3752 182.277C91.762 181.677 87.1488 181.594 82.5817 180.893C78.5221 180.266 74.1118 179.269 71.104 176.28C68.29 173.512 66.4078 169.951 62.5511 168.447V171.122C71.2332 169.083 80.395 167.607 89.234 169.508C94.6259 170.694 99.6777 173.091 104.008 176.516C108.338 179.941 111.833 184.306 114.228 189.28C114.994 190.885 117.384 189.483 116.618 187.887L116.646 187.951Z'
                fill={palette.bodyBorder}
            />
            {/* Inner Leaf */}
            <path
                d='M83.4125 175.782C94.9271 176.843 108.001 180.312 113.509 191.596C114.284 193.201 116.674 191.799 115.899 190.203C109.948 177.996 95.859 174.167 83.4125 173.014C81.641 172.848 81.6595 175.625 83.4125 175.782V175.782Z'
                fill={palette.bodyBorder}
            />
            {/* Stem */}
            <path
                d='M114.856 191.845C114.159 186.132 114.984 180.336 117.246 175.044C118.359 172.461 119.804 170.036 121.546 167.829C122.429 166.664 123.428 165.592 124.526 164.627C125.089 164.157 127.358 163.059 127.201 164.627L127.884 163.437C123.24 166.68 119.458 171.007 116.864 176.042C114.271 181.078 112.945 186.67 113.002 192.334C113.002 194.115 115.77 194.115 115.77 192.334C115.714 187.507 116.748 182.73 118.794 178.357C120.841 173.985 123.847 170.131 127.589 167.081C128.447 166.389 129.831 165.836 129.969 164.627C130.011 164.078 129.91 163.528 129.675 163.03C129.441 162.532 129.081 162.103 128.632 161.785C126.519 160.189 124.018 161.269 122.302 162.865C118.683 166.334 115.9 170.58 114.165 175.284C112.129 180.551 111.416 186.238 112.089 191.845C112.301 193.589 115.069 193.617 114.856 191.845Z'
                fill={palette.bodyBorder}
            />
            {displaySign && (
                <>
                    <rect
                        x='31.478'
                        y='2.41942'
                        width='180'
                        height='115'
                        transform='rotate(4.5795 31.478 2.41942)'
                        fill={palette.sign}
                        stroke={palette.signBorder}
                        strokeWidth='3'
                    />
                    <foreignObject
                        x='31.478'
                        y='2.41942'
                        width='180'
                        height='115'
                        transform='rotate(4.5795 31.478 2.41942)'
                    >
                        <div>
                            <p>{signText}</p>
                        </div>
                    </foreignObject>
                        <path
                            d='M162.662 200.109C161.527 196.375 161.992 192.373 162.558 188.503C165.158 169.733 168.402 151.068 172.29 132.507'
                            fill={palette.hands}
                        />
                        <path
                            d='M164.127 199.788C162.925 195.558 163.695 191.172 164.294 186.895C164.893 182.618 165.544 178.247 166.225 173.922C167.591 165.167 169.094 156.441 170.732 147.743C171.698 142.802 172.668 137.881 173.642 132.979C174.042 131.11 171.185 130.169 170.79 132.058C167.086 149.779 163.956 167.611 161.4 185.552C160.704 190.465 159.733 195.57 161.128 200.445C161.656 202.295 164.591 201.673 164.059 199.803L164.127 199.788Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M172.317 132.911C170.484 133.189 168.836 131.881 167.441 130.651C166.228 129.779 165.326 128.541 164.867 127.12C164.49 125.401 165.494 123.717 166.438 122.231C167.121 121.025 167.972 119.921 168.966 118.955C169.988 118.076 171.228 117.488 172.555 117.252C173.883 117.015 175.249 117.139 176.513 117.61C177.693 118.081 178.732 118.848 179.531 119.836C180.33 120.825 180.862 122.002 181.075 123.255C181.262 124.506 181.117 125.784 180.657 126.963C180.197 128.141 179.436 129.178 178.45 129.971C177.728 130.504 176.939 130.942 176.105 131.273C174.867 131.788 173.58 132.176 172.263 132.431'
                            fill={palette.hands}
                        />
                        <path
                            d='M171.996 131.445C170.898 131.573 169.782 130.681 169.009 130.052C168.063 129.235 166.809 128.322 166.415 127.129C166.02 125.936 166.806 124.566 167.433 123.549C168.116 122.287 168.981 121.132 169.999 120.121C170.963 119.288 172.169 118.788 173.439 118.695C174.709 118.601 175.975 118.919 177.051 119.601C178.08 120.264 178.87 121.239 179.305 122.384C179.74 123.528 179.796 124.782 179.467 125.961C178.477 129.249 175.012 130.396 171.961 131.003C170.076 131.415 170.717 134.346 172.602 133.933C176.527 133.156 180.543 131.438 182.118 127.459C182.768 125.72 182.847 123.818 182.344 122.031C181.84 120.243 180.78 118.663 179.318 117.518C177.84 116.378 176.042 115.731 174.177 115.669C172.311 115.607 170.474 116.133 168.924 117.172C167.339 118.384 166.028 119.916 165.076 121.669C163.938 123.188 163.342 125.045 163.385 126.943C163.61 129.186 165.315 130.758 166.937 132.133C168.558 133.508 170.408 134.618 172.675 134.358C173.062 134.27 173.399 134.034 173.614 133.7C173.828 133.365 173.902 132.96 173.819 132.572C173.727 132.187 173.49 131.853 173.157 131.64C172.824 131.426 172.421 131.35 172.033 131.427L171.996 131.445Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M67.791 217.258C64.3763 215.369 61.9227 212.173 59.6328 209.002C48.4254 193.723 37.7531 178.069 27.6158 162.043'
                            fill={palette.hands}
                        />
                        <path
                            d='M68.6195 216.007C64.81 213.809 62.3077 210.125 59.7588 206.639C57.21 203.152 54.6331 199.563 52.1089 195.985C46.9919 188.751 41.992 181.443 37.1094 174.061C34.3613 169.843 31.6298 165.636 28.9151 161.44C27.9004 159.821 25.1949 161.136 26.2262 162.766C35.9119 178.062 46.0856 193.037 56.7474 207.691C59.6695 211.701 62.5289 216.041 66.9247 218.567C68.5918 219.527 70.2647 217.037 68.5808 216.066L68.6195 216.007Z'
                            fill={palette.arms}
                        />
                        <path
                            d='M27.9163 162.314C26.7949 163.79 24.7016 163.999 22.8436 164.088C21.3658 164.307 19.8566 164.048 18.5368 163.348C17.0693 162.376 16.6167 160.469 16.259 158.745C15.9091 157.404 15.7516 156.019 15.7911 154.634C15.9132 153.291 16.3931 152.006 17.1808 150.911C17.9686 149.817 19.0353 148.954 20.2699 148.412C21.4452 147.929 22.725 147.755 23.9867 147.908C25.2485 148.06 26.4498 148.535 27.4755 149.285C28.4808 150.053 29.2677 151.071 29.7579 152.237C30.2482 153.403 30.4248 154.677 30.2701 155.933C30.1228 156.819 29.8617 157.682 29.4934 158.501C28.9637 159.732 28.3105 160.907 27.5437 162.007'
                            fill={palette.hands}
                        />
                        <path
                            d='M26.6657 161.486C25.9667 162.342 24.5443 162.48 23.5517 162.566C22.3038 162.64 20.768 162.858 19.654 162.276C18.54 161.695 18.1496 160.165 17.8907 158.998C17.5021 157.617 17.3181 156.186 17.3447 154.751C17.4565 153.482 17.9738 152.283 18.8199 151.332C19.6661 150.38 20.7961 149.726 22.0429 149.466C23.2433 149.225 24.4894 149.375 25.5986 149.893C26.7078 150.412 27.6218 151.272 28.2064 152.348C29.7859 155.396 28.0988 158.633 26.3319 161.193C25.2665 162.802 27.7679 164.458 28.8334 162.849C31.1083 159.558 32.7929 155.528 31.1522 151.576C30.407 149.875 29.1393 148.455 27.533 147.523C25.9268 146.592 24.0654 146.195 22.2189 146.393C20.3646 146.604 18.6235 147.392 17.2418 148.647C15.8602 149.902 14.9079 151.559 14.5197 153.384C14.2269 155.357 14.3532 157.37 14.8906 159.291C15.1319 161.173 15.9979 162.921 17.3499 164.253C19.0741 165.706 21.3926 165.646 23.5139 165.504C25.6352 165.361 27.7359 164.869 29.1808 163.103C29.3978 162.771 29.4752 162.366 29.3961 161.977C29.317 161.588 29.0878 161.246 28.7582 161.024C28.4243 160.812 28.0213 160.738 27.6337 160.817C27.2461 160.896 26.9041 161.122 26.6794 161.447L26.6657 161.486Z'
                            fill={palette.arms}
                        />
                </>
            )}
        </svg>
    );
}

export default Apple;
