import React, {useState} from 'react';
import {Avatars} from '../../../avatars';
import './ParticipantComponent.scss';
import {ReactComponent as KickUserIcon} from '../../../assets/Kick_Icon.svg';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import {Participant} from '../../../types/participant';


const ParticipantComponent = ({
                                  participant,
                                  showVote,
                                  deleteParticipant,
                              }: {
    participant: Participant;
    showVote: boolean;
    deleteParticipant: (publicId: string) => void;
}): React.ReactElement=> {
    const [kickDialogOpen, setKickDialogOpen] = useState(false);

    const currentImage = Object.values(Avatars)
        .find((avatar) => avatar.name === participant.avatar)
        ?.image({
            signText: showVote ? participant.vote : undefined,
            displaySign: !!participant.vote,
            grayScale: false,
            outline: false,
        });

    const openKickDialog = () => {
        setKickDialogOpen(true);
    };

    const cancelKickDialog = () => {
        setKickDialogOpen(false);
    };

    const kickParticipant = () => {
        deleteParticipant(participant.id);
        setKickDialogOpen(false);
    };

    return (
        <div className='team-votes__participant-wrapper'>
            {currentImage}
            <label className='team-votes__participant-name'>{participant.name}</label>
            <button
                aria-label={`Remove ${participant.name} from room`}
                className='team-votes__kick-button'
                onClick={openKickDialog}
            >
                <Tooltip
                    title='Remove from room'
                    arrow
                    placement='right'
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: '#363131',
                                fontFamily: 'Roboto',
                            },
                        },
                        arrow: {
                            sx: {
                                color: '#363131',
                            },
                        },
                    }}
                >
                    <KickUserIcon aria-hidden='true' tabIndex={-1}/>
                </Tooltip>
            </button>
            <Dialog open={kickDialogOpen} onClose={cancelKickDialog}>
                <DialogTitle aria-label={`Are you sure you want to remove ${participant.name} from the room?`}/>
                <DialogContent>
                    <DialogContentText aria-hidden='true'>
                        Are you sure you want to remove {participant.name} from the room?
                    </DialogContentText>
                    <DialogActions>
                        <Button aria-label='close' className='cancel' disableRipple onClick={cancelKickDialog}>
                            cancel
                        </Button>
                        <Button aria-label={`remove ${participant.name}`} className='confirm' disableRipple onClick={kickParticipant}>
                            remove
                        </Button>
                    </DialogActions>
                    <IconButton
                        aria-label='close'
                        disableRipple
                        onClick={cancelKickDialog}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ParticipantComponent;
